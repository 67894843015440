import { useBreakpoints } from '@utils/viewportUtils';
import React from 'react';
import MobileMenu from './components/MobileMenu';
import WebMenu from './components/WebMenu';
import Styles from './styles';

const MainNavigation: React.FC = () => {
  const { tabletAndDown } = useBreakpoints();
  return (
    <Styles.Nav>
      <Styles.Container centeredContent fullHeight variant="no-shadow">
        <Styles.LogoImage src="/static/icons/sgeLogo.svg" width={tabletAndDown ? '120px' : 'auto'} />
        <WebMenu />
        <MobileMenu />
      </Styles.Container>
    </Styles.Nav>
  );
};

export default MainNavigation;
