import { AppLink } from '@components';
import styled from 'styled-components';

export default {
  ArticleContainer: styled(AppLink)`
    display: flex;
    > * {
      cursor: pointer !important;
    }

    @media (hover: hover) {
      &:hover {
        ${'[data-transform="article-image"]'} {
          transform: scale(1.05);
        }
        a > div {
          text-decoration: underline;
          text-decoration-color: ${props => props.theme.colors.error};
        }
      }
    }
  `,
  ImageContainer: styled.div`
    overflow: hidden;
    min-width: 130px;
    height: 100px;
  `
};
