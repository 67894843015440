import { helpers } from '@components';
import styled from 'styled-components';
import { DesktopAndUp, LaptopAndUp, TabletAndUp } from 'src/components/stylingUtils';

export default {
  Filter: styled.span<{ disabled: boolean }>`
    cursor: pointer;
    ${helpers}

    a {
      text-decoration: none;
    }

    ${props =>
      props.disabled &&
      `
      pointer-events: none;
      opacity: 50%;
    `}
  `,
  FullScreenContainer: styled.div<{ isFullScreen: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${props => props.theme.colors.background};
    height: 100%;
    padding: ${props => props.theme.spacing.lgxx};
    ${props => (!props.isFullScreen ? 'display: none;' : '')}
  `,
  TitleSpacing: styled.div`
    width: 100%;
    margin-bottom: ${props => props.theme.spacing.sm};

    ${props => TabletAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.default};
    }

    ${props => LaptopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.md};
    }
  `,
  ResponsiveIframe: styled.div<{ compact?: boolean }>`
    margin-top: 25px;
    position: relative;

    ${props =>
      props.compact
        ? `height: 100%;`
        : `
          padding-bottom: 53.25%; /* 16:9 */

          ${LaptopAndUp(props)} {
            padding-bottom: 40%;
          }

          ${DesktopAndUp(props)} {
            padding-bottom: 53.25%;
          }
    `}

    iframe {
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,
  ErrorMessage: styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 50px;
  `,
  ErrorMessageText: styled.div`
    margin-top: 28px;
    max-width: 300px;
  `
};
