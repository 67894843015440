import React from 'react';
import { useIntl } from 'react-intl';
import SVGIcon from 'src/components/SVGIcon';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
import VideoFrame from 'src/components/VideoFrame';
import { scrollToElement, useBreakpoints } from 'src/utils/viewportUtils';
import LocalizedText, { NO_TRANSLATION_WARNING } from 'src/components/LocalizedText';
import * as CSS from 'csstype';
import { SpacingName } from 'src/components/Theme';

const RemoveQuotations = (value: string) => {
  return value.replace(/"/g, '').replace(/'/g, '');
};

const GetTagAttrs = (tag: string) => {
  const matches = tag.match(/[^\s\[\]]*?=".*?"/g);
  const result = {};
  matches?.forEach(attr => {
    const name_value = attr.split(/=(.+)/);
    result[RemoveQuotations(name_value[0])] = RemoveQuotations(name_value[1]);
  });

  return result;
};

const LinkTag = (props: {
  tag: string;
  index: string;
  showIconByDefault: boolean;
  anchorLinksTopOffset?: number;
  weight?: CSS.FontWeightProperty;
}) => {
  const attributes = GetTagAttrs(props.tag);
  //download, anchor, external, internal
  const linkType = attributes['type'] || 'external';
  const url = attributes['url'];
  const showIcon = attributes['showIcon'];

  return (
    <Text.Link
      highlightedByDefault={true}
      key={props.index}
      href={(linkType != 'anchor' && url) || '#'}
      onClick={(event: any) => {
        if (linkType == 'anchor') {
          let urlWithHash = url;
          if (!urlWithHash.startsWith('#')) urlWithHash = '#' + urlWithHash;
          scrollToElement({ elementSelector: urlWithHash, offsetTop: props.anchorLinksTopOffset || 138 });
        } else {
          if (url.indexOf('mailto') !== -1) {
            window.open(url, 'Mail');
            event.preventDefault();
          }
        }
      }}
      target={linkType == 'external' || linkType == 'download' ? '_blank' : '_self'}
      weight={props.weight || undefined}
      data-analytics-id={props['data-analytics-id']}
    >
      {attributes['textTranslationKey'] ? <LocalizedText id={attributes['textTranslationKey']} /> : attributes['text']}
      {!(linkType == 'anchor' || linkType == 'internal') &&
        (showIcon == 'yes' || (props.showIconByDefault && showIcon == null)) && (
          <SVGIcon
            paddingLeft="xsm"
            src={
              linkType == 'external' ? '/static/icons/externalLinkPrimary.svg' : '/static/icons/downloadPrimaryBold.svg'
            }
            size="16px"
          />
        )}
    </Text.Link>
  );
};

const VideoTag = (props: { tag: string }) => {
  const attributes = GetTagAttrs(props.tag);
  const url = attributes['url'];
  const youtubeId = attributes['youtubeId'];
  return youtubeId ? <VideoFrame url={`https://www.youtube.com/embed/${youtubeId}`} /> : <VideoFrame url={url} />;
};

const RichComponentTag = (props: {
  tag: string;
  anchorLinksTopOffset?: number;
  linksWeight?: CSS.FontWeightProperty;
  variant?: RichComponentVariant;
  renderOnly?: string[];
}) => {
  const attributes = GetTagAttrs(props.tag);
  const translationKey = attributes['translationKey'];
  return (
    (translationKey && (
      <RichComponent
        translationKey={translationKey}
        anchorLinksTopOffset={props.anchorLinksTopOffset}
        linksWeight={props.linksWeight}
        variant={props.variant}
        renderOnly={props.renderOnly}
      />
    )) ||
    //Wrong format of the [translation] tag - missing translationKey
    ''
  );
};

const RichComponentInline = (props: {
  translationKey: string;
  anchorLinksTopOffset?: number;
  linksWeight?: CSS.FontWeightProperty;
  renderOnly?: string[];
}) => {
  const intl = useIntl();
  const value = intl.formatMessage({ id: props.translationKey || NO_TRANSLATION_WARNING });
  const pattern = /\[.*?\]/gi;

  const matchRanges = [{}];

  let result;
  let lastStop = -1;
  while ((result = pattern.exec(value)) !== null) {
    const start = result.index;
    const stop = start + result[0].length;

    if (lastStop + 1 != start) {
      //adding text
      const text = value.substr(lastStop + 1, start - lastStop - 1);
      matchRanges.push({
        start: lastStop + 1,
        length: text.length,
        type: 'text',
        value: text
      });
    }

    matchRanges.push({ start: start, length: result[0].length, type: 'tag', value: result[0] });

    lastStop = stop - 1;
  }

  //adding last text
  if (lastStop < value.length - 1) {
    const text = value.substr(lastStop + 1, value.length - 1 - lastStop);

    matchRanges.push({
      start: lastStop + 1,
      length: text.length,
      type: 'text',
      value: text
    });
  }

  return (
    <>
      {matchRanges.map((item, index) => {
        if (item['type'] === 'text' && (!props.renderOnly || props.renderOnly.includes('text')))
          return (
            <LocalizedText
              supportHtml={true}
              onPageEditorTranslationKey={props.translationKey}
              content={item['value'].replace(/\n/g, '<br/>')}
            ></LocalizedText>
          );
        if (item['type'] === 'tag') {
          const value = item['value'];
          const linkTag = (value.match(/\[link.*?\]/g) || []).length > 0;
          const videoTag = (value.match(/\[video.*?\]/g) || []).length > 0;
          const translationTag = (value.match(/\[translation.*?\]/g) || []).length > 0;
          if (
            linkTag &&
            (!props.renderOnly || (props.renderOnly.includes('inlineLink') && GetTagAttrs(value)['isInline'] == 'yes'))
          )
            return (
              <LinkTag
                tag={value}
                index={String(index)}
                showIconByDefault={false}
                anchorLinksTopOffset={props.anchorLinksTopOffset}
                data-analytics-id={props['data-analytics-id']}
                weight={props.linksWeight}
              />
            );
          if (videoTag && (!props.renderOnly || props.renderOnly.includes('video'))) return <VideoTag tag={value} />;
          if (
            videoTag &&
            (!props.renderOnly || (props.renderOnly.includes('inlineVideo') && GetTagAttrs(value)['isInline'] == 'yes'))
          )
            return <VideoTag tag={value} />;

          if (translationTag && (!props.renderOnly || props.renderOnly.includes('translation')))
            return (
              <RichComponentTag
                tag={value}
                anchorLinksTopOffset={props.anchorLinksTopOffset}
                linksWeight={props.linksWeight}
                variant="inline"
                renderOnly={props.renderOnly}
              />
            );
        }
      })}
    </>
  );
};

//Returns list of tokens. A token can be text but it also can be a tag like link, video etc.
export const TextToTokens = (text: string) => {
  const pattern = /\[.*?\]/gi;
  const matchRanges = [{}];

  let result;
  let lastStop = -1;
  while ((result = pattern.exec(text)) !== null) {
    const start = result.index;
    const stop = start + result[0].length;

    if (lastStop + 1 != start) {
      //adding text
      const text_ = text.substr(lastStop + 1, start - lastStop - 1);
      matchRanges.push({
        start: lastStop + 1,
        length: text.length,
        type: 'text',
        value: text_
      });
    }

    matchRanges.push({ start: start, length: result[0].length, type: 'tag', value: result[0] });

    lastStop = stop - 1;
  }

  //adding last text
  if (lastStop < text.length - 1) {
    const text_ = text.substr(lastStop + 1, text.length - 1 - lastStop);
    matchRanges.push({
      start: lastStop + 1,
      length: text.length,
      type: 'text',
      value: text_
    });
  }

  return matchRanges;
};

//Gets a full text basing on the translationKey. Takes into account all subcomponents like translation, link etc. Returns just a plain text not the React component.
export const TranslationKey2FullText = (translationKey: string) => {
  const intl = useIntl();
  const value = intl.formatMessage({ id: translationKey || NO_TRANSLATION_WARNING });
  const tokens = TextToTokens(value);
  const parts: string[] = [];

  tokens.map(token => {
    if (token['type'] === 'text') parts.push(token['value'].replace(/\n/g, '<br/>'));

    //Translations component
    if (token['type'] === 'tag') {
      const value = token['value'];
      const translationTag = (value.match(/\[translation.*?\]/g) || []).length > 0;
      if (translationTag) {
        const attributes = GetTagAttrs(value);
        parts.push(TranslationKey2FullText(attributes['translationKey']));
      } else {
        parts.push(value);
      }
    }
  });

  return parts.join('');
};

const RichComponentStructurized = (props: {
  translationKey: string;
  anchorLinksTopOffset?: number;
  linksWeight?: CSS.FontWeightProperty;
  distanceToLinks?: SpacingName;
}) => {
  const { tabletAndDown } = useBreakpoints();
  const fullText = TranslationKey2FullText(props.translationKey);
  const hasTags = fullText.includes('[');
  const linkTags = (hasTags && fullText.match(/\[link.*?\]/g)) || [];
  const notInlineVideoTags = ((hasTags && fullText.match(/\[video.*?\]/g)) || []).filter(tag => {
    return GetTagAttrs(tag)['isInline'] != 'yes';
  });

  return (
    <>
      {/* Renders main text with inline elements */}
      <RichComponentInline
        renderOnly={['text', 'translation', 'inlineLink', 'inlineVideo']}
        {...props}
      ></RichComponentInline>
      {/* Rendera all components that go below the main text */}
      {linkTags.length > 0 && (
        //TODO: Responsivneness is to be parametrized in the future to make the component more generic.
        <Flex paddingTop={props.distanceToLinks || 'md'} vertical={tabletAndDown} gap="sm">
          {linkTags
            .filter(tag => {
              //Check for isInline attribute
              return GetTagAttrs(tag)['isInline'] != 'yes';
            })
            .map((tag, index) => {
              return (
                <div key={index}>
                  <LinkTag
                    tag={tag}
                    index={String(index)}
                    showIconByDefault={true}
                    anchorLinksTopOffset={props.anchorLinksTopOffset}
                    data-analytics-id={props['data-analytics-id']}
                    weight={props.linksWeight}
                  />
                </div>
              );
            })}
        </Flex>
      )}
      {notInlineVideoTags.length > 0 && (
        <Flex vertical paddingTop="xlg" gap="md">
          {notInlineVideoTags.map((tag, index) => {
            return <VideoTag key={index} tag={tag} />;
          })}
        </Flex>
      )}
    </>
  );
};

export type RichComponentVariant = 'inline' | 'structurized';

/**
  Possible tags:
  [link type="download" url="" text="" showIcon="no"]
  [link type="download" url=""  textTranslationKey=""]
  [link type="anchor" url="#some-section-id" text="Some text" isInline="yes"] 
  [link url="" text="sdsd"] 
  [video url=""]
  [video youtubeId=""]
  [translation translationKey=""]

  Examples:
  [link text="A1 form leaflet" url="https://google.com" type="download" ]
  [link text="A1 registration form" url="https://google.com" ]
  [video url="https://www.youtube.com/embed/0ivDlIkzPzE"]
  [video youtubeId="0ivDlIkzPzE"]
  [translation translationKey="postingOfEmployees.toBeTakenByEmployees.employmentContract"]

  distanceToLinks is only valid for structurized 
 */
export const RichComponent = (props: {
  'data-analytics-id'?: string;
  translationKey: string;
  variant?: RichComponentVariant;
  anchorLinksTopOffset?: number;
  linksWeight?: CSS.FontWeightProperty;
  distanceToLinks?: SpacingName;
  renderOnly?: string[];
}) => {
  return props.variant == 'inline' ? <RichComponentInline {...props} /> : <RichComponentStructurized {...props} />;
};

RichComponent.defaultProps = {
  variant: 'structurized'
};
