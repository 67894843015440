import moment from 'moment';
import React from 'react';
import { CMSEvent } from '@redux-modules/event/interfaces';
import { formatDate } from 'src/components/Events/EventTeaser';
import { useEventLocation } from 'src/components/Events/use-event-location';
import SharedStyles from '../../../../styles';
import Styles from './styles';
import { LocalizedText, ControlButton, Flex, Text } from '@components';

const convertDate = (date: CMSEvent['dates'][0]) => {
  const start = moment(date.start);
  const end = date.end && moment(date.end);

  return {
    dayMonth: formatDate('DD.MM', start, end),
    year: formatDate('YYYY', start, end)
  };
};

const EventPreview: React.FC<{ event: CMSEvent }> = ({ event }) => {
  const {
    dates,
    drupalNodeId,
    image,
    address,
    information: { place }
  } = event;
  const { dayMonth, year } = convertDate(dates[0]);
  const eventLink = `event/${drupalNodeId}`;
  const eventPlace = useEventLocation(address, place);
  return (
    <Styles.EventContainer href={eventLink} data-analytics-id="41-click-events-link">
      <Styles.RelativeDiv>
        <SharedStyles.PreviewImage src={image?.teaserMd} data-transform="event-image" />
        <Styles.DateContainer justifyContent="center" alignItems="center" vertical>
          <Styles.DateHead>
            <Text.Tiny color="lightGraySecondary">{year}</Text.Tiny>
          </Styles.DateHead>
          <Styles.DateBody alignItems="center" justifyContent="center" fullWidth fullHeight>
            <Text.Span13_18_bold align="center">{dayMonth}</Text.Span13_18_bold>
          </Styles.DateBody>
        </Styles.DateContainer>
      </Styles.RelativeDiv>
      <Flex vertical gap="xsm">
        <Text.Span18_28_bold>{event.title}</Text.Span18_28_bold>
        {eventPlace && (
          <Flex>
            <ControlButton leftIcon="pinOnWhiteSecondary" variant="Span14_22">
              {eventPlace}
            </ControlButton>
          </Flex>
        )}
        <SharedStyles.Click>
          <ControlButton rightIcon="semiArrowRightTinyPrimary" active variant="Span13_18_bold" rightSpacing="xxsm">
            <LocalizedText id="menus.eventDetails" />
          </ControlButton>
        </SharedStyles.Click>
      </Flex>
    </Styles.EventContainer>
  );
};

export default EventPreview;
