import { Button, Flex, Text, ControlButton } from '@components';
import { TabletAndUpNormalized } from 'src/components/stylingUtils';
import styled from 'styled-components';

export default {
  Container: styled(Flex)`
    position: absolute;
    padding-right: ${props => props.theme.spacing.default};
    right: 0;
  `,
  NestedControlButton: styled(ControlButton).attrs({ activeOnHover: true, variant: 'Span16_20', leftSpacing: '12px' })`
    padding: 14px 0;
  `,
  Delimiter: styled.div`
    width: 100vw;
    height: 1px;
    background-color: ${props => props.theme.colors.lightGraySecondary};
    position: relative;
    left: -${props => props.theme.spacing.default};
    bottom: 0;
    margin: ${props => props.theme.spacing.sm} 0;
  `,
  AuthButton: styled(Button.Medium)`
    height: 42px;
    ${props => TabletAndUpNormalized(props)} {
      max-width: 300px;
    }
  `,
  Overlay: styled.div<{ enabled?: boolean }>`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    opacity: ${props => (props.enabled ? 0.95 : 0)};
    background-color ${props => props.theme.colors.white};
    z-index: -5;
    pointer-events: none;
  `,
  LanguageText: styled(Text.Span16_26)`
    cursor: pointer;

    padding: ${props => props.theme.spacing.xsm};

    &:first-child {
      padding-left: 0;
    }
  `
};
