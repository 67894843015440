import React from 'react';
import Styles from './styles';
import { User } from 'src/redux/modules/user/interfaces';
import { CMSConsultant } from '@redux-modules/consultant/interfaces';
import ExternalLinks from './components/ExternalLinks';
import HeaderControls from './components/HeaderControls';
import { useBreakpoints } from '@utils/viewportUtils';

export type TopNavigationProps = {
  user: User;
  consultant?: CMSConsultant;
};

const TopNavigation: React.FC<TopNavigationProps> = ({ user }) => {
  const { tabletAndUpInTheMiddle } = useBreakpoints();

  return (
    <Styles.Nav>
      <Styles.Container fullHeight justifyContent="space-between">
        <ExternalLinks />
        {tabletAndUpInTheMiddle && <HeaderControls user={user} />}
      </Styles.Container>
    </Styles.Nav>
  );
};

export default TopNavigation;
