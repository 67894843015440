import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'src/redux/reducers';
import { getWebOptions } from '../../../../options';
import CTAButton from '../../shared/CTAButton';
import Styles from './styles';

const WebMenu: React.FC<WebMenuReduxProps> = ({ user }) => {
  const filteredOptions = React.useMemo(() => {
    if (!user) {
      return [];
    }
    return getWebOptions(user).filter(option => option.enabled);
  }, [user]);
  return (
    <Styles.Container alignItems="center" justifyContent="space-between" gap="xmd" fullHeight>
      {filteredOptions.map(option => (
        <option.Component key={option.translationKey} option={option} />
      ))}
      {!user?.onboarded && <CTAButton />}
    </Styles.Container>
  );
};

const mapStateToProps = ({ userReducer }: AppState) => ({
  user: userReducer.user
});
export type WebMenuReduxProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(WebMenu);
