import React, { useEffect, useMemo } from 'react';
import getConfig from 'next/config';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import OECVisualizer, { OECVisualizerProps } from './components';
import { useVisualizer } from './components/context';
import { doPublishOnBackendQueue } from '../../redux/modules/queueing/actions';
import { doCheckOec } from '../../redux/modules/oec/actions';

const appendHSCodeSection = (hsCode: string) => {
  const code = Number(hsCode.substr(0, 2));
  if (code >= 0 && code <= 5) {
    return `1${hsCode}`;
  } else if (code >= 6 && code <= 14) {
    return `2${hsCode}`;
  } else if (code === 15) {
    return `3${hsCode}`;
  } else if (code >= 16 && code <= 24) {
    return `4${hsCode}`;
  } else if (code >= 25 && code <= 27) {
    return `5${hsCode}`;
  } else if (code >= 28 && code <= 38) {
    return `6${hsCode}`;
  } else if (code >= 39 && code <= 40) {
    return `7${hsCode}`;
  } else if (code >= 41 && code <= 43) {
    return `8${hsCode}`;
  } else if (code >= 44 && code <= 46) {
    return `9${hsCode}`;
  } else if (code >= 47 && code <= 49) {
    return `10${hsCode}`;
  } else if (code >= 50 && code <= 63) {
    return `11${hsCode}`;
  } else if (code >= 64 && code <= 67) {
    return `12${hsCode}`;
  } else if (code >= 68 && code <= 70) {
    return `13${hsCode}`;
  } else if (code === 71) {
    return `14${hsCode}`;
  } else if (code >= 72 && code <= 83) {
    return `15${hsCode}`;
  } else if (code >= 84 && code <= 85) {
    return `16${hsCode}`;
  } else if (code >= 86 && code <= 89) {
    return `17${hsCode}`;
  } else if (code >= 90 && code <= 92) {
    return `18${hsCode}`;
  } else if (code === 93) {
    return `19${hsCode}`;
  } else if (code >= 94 && code <= 96) {
    return `20${hsCode}`;
  } else if (code === 97) {
    return `21${hsCode}`;
  } else {
    return hsCode;
  }
};

const { publicRuntimeConfig } = getConfig();

export type VisualizerContainerProps = ReducerProps & OECVisualizerProps;

const OECVisualizerContainer: React.FC<VisualizerContainerProps> = props => {
  const { doCheckOec, market } = props;
  const { hasProvider, selectedProduct, visualizerState } = useVisualizer();

  if (!hasProvider) {
    throw 'Called OECVisualizer or OECVisualizerContainer. But no VisualizerProvider was found. Wrap your OECVisualizer or OECVisualizerContainer with the VisualizerProvider component';
  }

  const url = useMemo(() => {
    const parts = {
      dataset: publicRuntimeConfig.OEC_VISUALIZER_DATASET || 'hs17',
      market: market ? `${market.ISOCode3Letter.toLowerCase()}/show` : 'show/all',
      product: selectedProduct ? appendHSCodeSection(selectedProduct.HSCode) : 'all',
      year: publicRuntimeConfig.OEC_VISUALIZER_YEAR || '2021'
    };
    return `https://oec.world/en/visualize/embed/${visualizerState}/${parts.dataset}/import/${parts.market}/${parts.product}/${parts.year}/`;
  }, [market, selectedProduct, visualizerState]);

  useEffect(() => void doCheckOec(url), [url]);

  return <OECVisualizer {...props} url={url} />;
};

const mapStateToProps = ({ productReducer, userReducer, oecReducer }: AppState) => ({
  products: productReducer.products,
  user: userReducer.user,
  oec: oecReducer
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ doPublishOnBackendQueue, doCheckOec }, dispatch);

export type ReducerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OECVisualizerContainer);
