import React from 'react';
import { NextPage } from 'next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import LatestContent from '../../../components/Articles/LatestContent';
import { AppState } from '../../../redux/reducers';
import { doRetrieveArticles } from '../../../redux/modules/article/actions';

const MarketLatestContent: NextPage<MarketLatestContentProps> = props => (
  <LatestContent
    {...props}
    doRetrieveLatestContent={() => props.doRetrieveArticles(12, 0, props.articlesSlug, 'all')}
    articles={props.articles.slice(0, 3)}
  />
);

const mapStateToProps = ({ articleReducer }: AppState) => ({
  articles: articleReducer.articles,
  loading: articleReducer.loading,
  error: articleReducer.error
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doRetrieveArticles
    },
    dispatch
  );

export type MarketLatestContentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    articlesSlug: string;
    tocGutter?: boolean;
    hideDashboardLatestContent?: boolean;
  };

export default connect(mapStateToProps, mapDispatchToProps)(MarketLatestContent);
