import React from 'react';
import WebMenuItem from '../../shared/WebMenuItem';
import { WebMenuOption } from '../../../../../../options';
import useOpenableState from '@hooks/useOpenableState';
import { connect } from 'react-redux';
import { AppState } from 'src/redux/reducers';
import EventMenuContent from './components/EventMenuContent';

const EventMenuItem: React.FC<{ option: WebMenuOption } & EventMenuItemReduxProps> = ({ onboarded, option }) => {
  const [isOpen, { toggle, close }] = useOpenableState(false);

  return (
    <WebMenuItem
      option={option}
      handleClick={toggle}
      handleClose={close}
      isOpen={isOpen}
      paddingVertical="64px"
      paddingHorizontal="lgxx"
      isLightUser={!onboarded}
    >
      <EventMenuContent handleClose={close} />
    </WebMenuItem>
  );
};

const mapStateToProps = ({ userReducer }: AppState) => ({
  onboarded: userReducer.user?.onboarded
});

export type EventMenuItemReduxProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(EventMenuItem);
