import { Flex } from '@components';
import React from 'react';
import { connect } from 'react-redux';
import { userMenuOptions } from 'src/elements/Navbar/options';
import MobileMenuItem from '../../shared/MobileMenuItem';

const NestedProfileMenu: React.FC = () => {
  return (
    <Flex vertical>
      {userMenuOptions.map(option => (
        <MobileMenuItem option={option} key={option.translationKey} href={option.link} />
      ))}
    </Flex>
  );
};

export default connect()(NestedProfileMenu);
