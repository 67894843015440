import React from 'react';
import SharedStyles from '../../../../styles';
import { LocalizedText, AppLink, Flex, Spinner, Text } from '@components';
import EventPreview from '../EventPreview';
import { connect } from 'react-redux';
import { AppState } from 'src/redux/reducers';
import { useIntl } from 'react-intl';

const EventMenuContent: React.FC<EventMenuContentReduxProps & { handleClose: () => void }> = ({
  handleClose,
  user,
  loading,
  events = []
}) => {
  const intl = useIntl();
  return (
    <>
      <Flex justifyContent="center">
        <SharedStyles.MenuColumn>
          <Text.Span22_22_bold marginBottom="md">
            <LocalizedText id="menus.eventsByType" />
          </Text.Span22_22_bold>
          <Flex vertical gap="default">
            {!!user?.CRMGuid && (
              <AppLink href="my-events" data-analytics-id="140-my-events-big-menu">
                <SharedStyles.MenuLink>
                  <LocalizedText id="menus.myEvents" />
                </SharedStyles.MenuLink>
              </AppLink>
            )}
            <AppLink href="events">
              <SharedStyles.MenuLink>
                <LocalizedText id="menus.relevantEvents" />
              </SharedStyles.MenuLink>
            </AppLink>
            <AppLink href={intl.formatMessage({ id: 'menus.tradeForumLink' })}>
              <SharedStyles.MenuLink>
                <LocalizedText id="menus.backToGrowth" />
              </SharedStyles.MenuLink>
            </AppLink>
          </Flex>
        </SharedStyles.MenuColumn>
        <SharedStyles.Delimiter />
        <SharedStyles.ContentColumn>
          <Text.Span22_22_bold marginBottom="md">
            <LocalizedText id="menus.upcomingEvents" />
          </Text.Span22_22_bold>
          {loading ? (
            <Spinner.Default />
          ) : (
            events.slice(0, 2).map((event, index) => <EventPreview event={event} key={index} />)
          )}
        </SharedStyles.ContentColumn>
      </Flex>
      <SharedStyles.CloseButtonContainer>
        <SharedStyles.CloseButton onClick={handleClose}>
          <img src="/static/icons/cross.svg" />
        </SharedStyles.CloseButton>
      </SharedStyles.CloseButtonContainer>
    </>
  );
};

const mapStateToProps = ({ eventReducer, userReducer }: AppState) => ({
  events: eventReducer.menuEvents,
  loading: eventReducer.menuEventsLoading,
  user: userReducer.user
});

export type EventMenuContentReduxProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(EventMenuContent);
