import styled from 'styled-components';
import { TabletAndUp } from '../../components/stylingUtils';
import Text from '../../components/Text';

export default {
  Grid: styled.div<{ childCount: number }>`
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;

    ${props => TabletAndUp(props)} {
      grid-template-columns: ${({ childCount }) => `repeat(${childCount > 1 ? 3 : 1}, 1fr)`};
    }
  `,

  Container: styled.div<{ clickable?: boolean }>`
    &:hover {
      cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};

      & > button {
        cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};

        &:nth-child(2) {
          color: ${({ clickable, theme }) => (clickable ? theme.colors.primary : 'initial')};
        }
      }
    }
  `,

  Container16_9: styled.button`
    display: block;
    position: relative;
    padding-bottom: 52.25%; /* 16:9 */
    padding-top: 25px;
    margin: 0;
    line-height: 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    background: none;
    outline: none;
  `,

  Thumbnail: styled.span<{ $src: string }>`
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${({ $src, theme }) => ($src ? `background-image:  url(${$src});` : `background-color: ${theme.colors.gray};`)}
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  PlayIcon: styled.img.attrs<{ active?: boolean }>(({ active }) => ({
    src: `/static/icons/youtube${active ? 'Primary' : ''}.svg`
  }))<{ active?: boolean }>`
    width: 3rem;
  `,

  Title: styled(Text.SmallerParagraph).attrs({ as: 'button' })`
    padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} 0`};
    display: block;
    border: 0;
    border-radius: 0;
    background: none;
    outline: none;
    width: 100%;
    text-align: left;
  `
};
