import moment from 'moment-timezone';
import parse from 'html-react-parser';
import React, { FC, useState } from 'react';
import ResponsiveIframe from '../../components/ResponsiveIframe';
import Styles from './styles';
import { PublishOnBackendQueueAction } from 'src/redux/modules/queueing/types';
import { User } from 'src/redux/modules/user/interfaces';

type OEmebed = { html: string; thumbnail_url: string | null; title: string; url: string };
type PublishOnBackendQueueActionFunc = (
  event: PublishOnBackendQueueAction['event'],
  variables: { userId: number; videoTitle: string; startDate: string }
) => void;

const Video: FC<{
  oEmbed: OEmebed;
  user?: User;
  doPublishOnBackendQueue?: PublishOnBackendQueueActionFunc;
}> = ({ oEmbed: { title, html, thumbnail_url }, user, doPublishOnBackendQueue }) => {
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [iframePrefix, videoURI, iframeSuffix] = html.match(/(.*src=")([\S]+)(".*)/) || [];
  const startVideo = () => {
    if (isPlayingVideo) {
      return;
    }
    setIsPlayingVideo(true);
    if (doPublishOnBackendQueue && user?.id) {
      doPublishOnBackendQueue('clicked_view_video_on_event_publication', {
        userId: user.id,
        videoTitle: title,
        startDate: moment.tz('Europe/Zurich').format('MM/DD/YYYY HH:mm:ss')
      });
    }
  };
  const [isHoverVideo, setIsHoverVideo] = useState(false);

  return (
    <Styles.Container
      clickable={!isPlayingVideo}
      onMouseEnter={() => setIsHoverVideo(true)}
      onMouseLeave={() => setIsHoverVideo(false)}
      data-analytics-id="video-container"
    >
      {isPlayingVideo ? (
        <ResponsiveIframe>{parse(`${iframePrefix}${videoURI}&autoplay=1${iframeSuffix}`)}</ResponsiveIframe>
      ) : (
        <Styles.Container16_9 onClick={startVideo}>
          <Styles.Thumbnail $src={thumbnail_url || ''}>
            <Styles.PlayIcon active={isHoverVideo} />
          </Styles.Thumbnail>
        </Styles.Container16_9>
      )}
      {title && <Styles.Title onClick={startVideo}>{title}</Styles.Title>}
    </Styles.Container>
  );
};

export const Videos: FC<{
  oEmbeds: OEmebed[];
  doPublishOnBackendQueue?: PublishOnBackendQueueActionFunc;
  user?: User;
}> = ({ oEmbeds, doPublishOnBackendQueue, user }) =>
  oEmbeds.length === 0 ? null : (
    <Styles.Grid childCount={oEmbeds.length}>
      {oEmbeds.map(video => (
        <Video key={video.url} oEmbed={video} doPublishOnBackendQueue={doPublishOnBackendQueue} user={user} />
      ))}
    </Styles.Grid>
  );
