import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'src/redux/reducers';
import TopNavigation from './components/TopNavigation';
import MainNavigation from './components/MainNavigation';
import { doRetrieveAllCachedMarkets } from '@redux-modules/cachedMarketList/actions';
import { doRetrieveMenuEvents } from '@redux-modules/event/actions';
import { doRetrieveMenuArticles } from '@redux-modules/article/actions';
import { doRetrieveReportUuid } from '@redux-modules/report/actions';
import useScroll from '@hooks/useScroll';
import Styles from './styles';
import { User } from '@redux-modules/user/interfaces';

const Navbar: React.FC<NavbarProps & { user: User }> = ({
  marketsList,
  user,
  isChatOpen,
  doRetrieveAllCachedMarkets,
  doRetrieveMenuEvents,
  doRetrieveMenuArticles
}) => {
  const { isScrollingDown } = useScroll();

  useEffect(() => {
    if (!marketsList?.length) {
      doRetrieveAllCachedMarkets();
    }
    doRetrieveMenuEvents({ limit: 2 });
    doRetrieveMenuArticles();
  }, []);

  useEffect(() => {
    if (user?.firstName) {
      doRetrieveReportUuid();
    }
  }, [user?.firstName]);

  return (
    <Styles.Header isScrollingDown={isScrollingDown} isChatOpen={isChatOpen} data-test-id="navbar">
      <TopNavigation user={user} />
      <MainNavigation />
    </Styles.Header>
  );
};

const mapStateToProps = ({ cachedMarketListReducer, intercomReducer }: AppState) => ({
  marketsList: cachedMarketListReducer.cachedMarketList,
  isChatOpen: intercomReducer.isChatOpen
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doRetrieveAllCachedMarkets,
      doRetrieveMenuEvents,
      doRetrieveMenuArticles,
      doRetrieveReportUuid
    },
    dispatch
  );

export type NavbarProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
