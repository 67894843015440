import React from 'react';
import { CMSConsultant } from '@redux-modules/consultant/interfaces';
import Styles from './styles';
import { ControlButton, LocalizedText, Button, Flex, Spinner, Text } from '@components';
import { useIntl } from 'react-intl';
import { RequestResult } from 'src/interfaces/requests';

type ConsultantTileProps = {
  consultant?: CMSConsultant;
  bookingUrl?: string;
  defaultConsultant: Partial<CMSConsultant>;
  message?: string;
  loading: boolean;
  error?: RequestResult;
  isEmailSent: boolean;

  handleMessageChange?(value: string): void;
  handleLiveChatClick?(): void;
  handleMessageButtonClick?(): void;
};

const ConsultantTile: React.FC<ConsultantTileProps> = props => {
  const intl = useIntl();
  const consultant = props.consultant ? props.consultant : props.defaultConsultant;
  const phoneNumber = consultant.phoneNumber || consultant.mobilePhoneNumber;
  const bookingUrl = props.bookingUrl || props.defaultConsultant.url;
  return (
    <Styles.Container vertical>
      <Flex vertical alignItems="center" marginBottom="default">
        <Flex vertical gap="default" alignItems="center" marginBottom="default">
          <Styles.Avatar src={consultant?.image?.teaserSm || '/static/img/default-consultant.png'} />
          <Text.Span20_24 align="center" weight={700}>
            {props.consultant ? (
              <>
                <LocalizedText
                  id="consultantPresentation"
                  description="Consultant presentation"
                  values={{ name: `${props.consultant.firstName} ${props.consultant.lastName}` }}
                />
                <br />
                <LocalizedText id="consultantHelp" description="Consultant help" />
              </>
            ) : (
              <LocalizedText id="contactUs.title" />
            )}
          </Text.Span20_24>
        </Flex>
        {!props.consultant && (
          <Flex marginBottom="default" vertical>
            <Text.Span14_22 align="center">
              <LocalizedText
                id="contactUs.extraBottomSection"
                supportHtml
                supportLineBreaksInHtml
                content={intl.formatMessage({ id: 'contactUs.extraBottomSection' })}
              />
            </Text.Span14_22>
          </Flex>
        )}
        <Styles.ContactLinksContainer vertical gap="sm" justifyContent="center" alignItems="center">
          {phoneNumber && (
            <ControlButton
              leftIcon="phoneRed-o"
              href={`tel:${phoneNumber}`}
              variant="Span14_22_bold"
              external
              data-analytics-id="145-contact-call-me"
            >
              {phoneNumber}
            </ControlButton>
          )}
          {bookingUrl && (
            <ControlButton
              leftIcon="calendarPrimary"
              variant="Span14_22_bold"
              external
              href={bookingUrl}
              target="_blank"
              data-analytics-id="146-contact-calenso"
            >
              <LocalizedText id="consultantScheduleMeeting" />
            </ControlButton>
          )}
          <ControlButton
            leftIcon="contact-chatPrimary"
            variant="Span14_22_bold"
            onClick={props.handleLiveChatClick}
            data-analytics-id="147-contact-start-live-chat"
          >
            <LocalizedText id="exportGuide.report.footer.liveChat" />
          </ControlButton>
        </Styles.ContactLinksContainer>
        {!props.isEmailSent && (
          <Flex vertical marginTop="lg" fullWidth>
            <Text.Span14_22 marginBottom="12px" align="center" color="onWhiteSecondary">
              <LocalizedText id="consultantDirectMessageHeader" />
            </Text.Span14_22>
            <Styles.Divider />
          </Flex>
        )}
      </Flex>
      {props.loading ? (
        <Spinner.Default />
      ) : props.error ? (
        <Text.Span14_24_bold align="center">
          <LocalizedText id="commonApiErrors.general" />
        </Text.Span14_24_bold>
      ) : props.isEmailSent ? (
        <Text.Span20_24 marginTop="sm" align="center">
          <LocalizedText id="confirmationMessage" />
        </Text.Span20_24>
      ) : (
        <>
          <Flex vertical fullWidth marginBottom="md">
            <Text.Span16_26_bold marginBottom="12px">
              <LocalizedText id="consultantMessageTitle" />
            </Text.Span16_26_bold>
            <Styles.Textarea
              placeholder={intl.formatMessage({ id: 'consultantMessagePlaceholder' })}
              placeholderColor="grey"
              padding="sm"
              placeholderSize="16px"
              fontSize="16px"
              height="96px"
              value={props.message}
              onChange={event => props.handleMessageChange?.(event.target.value)}
            />
          </Flex>
          <Button.Medium
            height="50px"
            disabled={!props.message}
            onClick={props.handleMessageButtonClick}
            data-analytics-id="148-contact-send-me-an-email-click"
          >
            <Text.Span14_22 weight={700} color="inherit">
              <LocalizedText id="consultantMessageButton" />
            </Text.Span14_22>
          </Button.Medium>
        </>
      )}
    </Styles.Container>
  );
};

export default React.memo(ConsultantTile);
