import styled from 'styled-components';

export default {
  Header: styled.header<{ isScrollingDown: boolean; isChatOpen: boolean }>`
    box-shadow: 1px 1px 4px rgb(0 0 0 / 8%);
    z-index: ${props => (props.isChatOpen ? 9999 : 2147483006)};
    position: sticky;
    top: ${props => (props.isScrollingDown ? '-40px' : 0)};
    transition: top 120ms ease-in;
  `
};
