import {
  PublishOnBackendQueueAction,
  PublishOnBackendQueueFailureAction,
  PublishOnBackendQueueSuccessAction,
  PUBLISH_ON_BACKEND_QUEUE,
  PUBLISH_ON_BACKEND_QUEUE_FAILURE,
  PUBLISH_ON_BACKEND_QUEUE_SUCCESS
} from './types';

export const doPublishOnBackendQueue = (
  event: PublishOnBackendQueueAction['event'],
  variables: PublishOnBackendQueueAction['variables'],
  onSuccess?: PublishOnBackendQueueAction['onSuccess'],
  onFailure?: PublishOnBackendQueueAction['onFailure']
): PublishOnBackendQueueAction => ({
  type: PUBLISH_ON_BACKEND_QUEUE,
  event,
  variables,
  onSuccess,
  onFailure
});

export const doPublishOnBackendQueueSuccess = (): PublishOnBackendQueueSuccessAction => ({
  type: PUBLISH_ON_BACKEND_QUEUE_SUCCESS
});

export const doPublishOnBackendQueueFailure = (
  error: PublishOnBackendQueueFailureAction['error']
): PublishOnBackendQueueFailureAction => ({
  type: PUBLISH_ON_BACKEND_QUEUE_FAILURE,
  error
});
