export const CHECK_OEC = 'oec/CHECK_OEC';
export const CHECK_OEC_SUCCESS = 'industries/CHECK_OEC_SUCCESS';
export const CHECK_OEC_FAILURE = 'industries/CHECK_OEC_FAILURE';

export interface CheckOecAction {
  type: typeof CHECK_OEC;
  url: string;
}

export interface CheckOecSuccessAction {
  type: typeof CHECK_OEC_SUCCESS;
}

export interface CheckOecFailureAction {
  type: typeof CHECK_OEC_FAILURE;
}
