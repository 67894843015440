import React from 'react';

import EventMenuItem from './components/MainNavigation/components/WebMenu/components/EventMenuItem';
import ExpertMenuItem from './components/MainNavigation/components/WebMenu/components/ExpertMenuItem';
import LinkMenuItem from './components/MainNavigation/components/WebMenu/components/LinkMenuItem';
import MarketMenuItem from './components/MainNavigation/components/WebMenu/components/MarketMenuItem';

import NestedMarketMenu from './components/MainNavigation/components/MobileMenu/components/NestedMarketMenu';
import NestedExpertMenu from './components/MainNavigation/components/MobileMenu/components/NestedExpertMenu';
import NestedEventMenu from './components/MainNavigation/components/MobileMenu/components/NestedEventMenu';
import NestedProfileMenu from './components/MainNavigation/components/MobileMenu/components/NestedProfileMenu';
import { locale2ContactUrl } from './components/TopNavigation/components/HeaderControls';
import { User } from '@redux-modules/user/interfaces';

export type MenuOption = {
  translationKey: string;
  activeRoutePrefixes?: string[];
  link?: string;
  target?: string;
  external?: boolean;
  dataAnalyticsId?: string;
  leftIcon?: string;
  leftActiveIcon?: string;
  enabled?: boolean;
};

export type WebMenuOption = {
  Component: React.FC<{ option: WebMenuOption }>;
} & MenuOption;

export type MobileMenuOption = {
  NestedComponent?: React.FC;
  hideExpandIcon?: boolean;
} & MenuOption;

const getOptions: (user: User) => MenuOption[] = user => [
  {
    translationKey: 'menus.dashboard',
    activeRoutePrefixes: ['/cockpit'],
    link: 'cockpit',
    enabled: user?.onboarded
  },
  {
    translationKey: 'menus.exploreMarkets',
    activeRoutePrefixes: ['/markets'],
    enabled: user?.onboarded
  },
  {
    translationKey: 'globalNav.supplierSourcing',
    activeRoutePrefixes: ['/supplier-sourcing'],
    link: 'supplier-sourcing?q=*&_semc=grouped',
    enabled: user?.supsInfrastructure === 'yes'
  },
  {
    translationKey: 'menus.businessOpportunities',
    activeRoutePrefixes: ['/business'],
    link: 'business-opportunities',
    dataAnalyticsId: '114-menu',
    enabled: user?.onboarded
  },
  {
    translationKey: 'menus.toolsResources',
    activeRoutePrefixes: ['/article'],
    dataAnalyticsId: '62-tools-and-ressources-click-menu-item',
    enabled: user?.onboarded
  },
  {
    translationKey: 'menus.events',
    activeRoutePrefixes: ['/event', '/my-events'],
    enabled: true
  }
];

export const getMobileOptions: (user: User) => MobileMenuOption[] = user => {
  const options = getOptions(user);
  return [
    {
      ...options[0]
    },
    {
      ...options[1],
      NestedComponent: NestedMarketMenu
    },
    {
      ...options[2]
    },
    {
      ...options[3]
    },
    {
      ...options[4],
      NestedComponent: NestedExpertMenu
    },
    {
      ...options[5],
      NestedComponent: NestedEventMenu
    }
  ];
};

export const additionalMobileOptions = (locale: string): MobileMenuOption[] => [
  {
    translationKey: 'globalNav.contact',
    leftIcon: 'contactOnWhiteSecondary',
    leftActiveIcon: 'contactPrimary',
    link: locale2ContactUrl[locale],
    target: '_blank',
    external: true
  },
  {
    translationKey: 'menus.profile',
    activeRoutePrefixes: ['/profile'],
    leftIcon: 'userOnWhiteSecondary',
    leftActiveIcon: 'userPrimary',
    NestedComponent: NestedProfileMenu,
    hideExpandIcon: true
  }
];

export const getWebOptions: (user: User) => WebMenuOption[] = user => {
  const options = getOptions(user);
  return [
    {
      ...options[0],
      Component: LinkMenuItem
    },
    {
      ...options[1],
      Component: MarketMenuItem
    },
    {
      ...options[2],
      Component: LinkMenuItem
    },
    {
      ...options[3],
      Component: LinkMenuItem
    },
    {
      ...options[4],
      Component: ExpertMenuItem
    },
    {
      ...options[5],
      Component: EventMenuItem
    }
  ];
};

export const userMenuOptions: MenuOption[] = [
  {
    translationKey: 'preferencesUrl',
    leftIcon: 'preferencesOnWhiteSecondary',
    leftActiveIcon: 'preferencesPrimary',
    link: 'profile'
  },
  {
    translationKey: 'marketsUrl',
    leftIcon: 'starOnWhiteSecondary',
    leftActiveIcon: 'starPrimary',
    link: 'explore-markets'
  },
  {
    translationKey: 'productsUrl',
    leftIcon: 'cubeOnWhiteSecondary',
    leftActiveIcon: 'cubePrimary',
    link: 'profile#user-products'
  },
  {
    translationKey: 'menus.myEvents',
    leftIcon: 'calendar-smallOnWhiteSecondary',
    leftActiveIcon: 'calendar-smallPrimary',
    link: 'my-events',
    dataAnalyticsId: '139-my-events-user-profile-drop-down'
  }
];
