import { LocalizedText } from '@components';
import { useRouter } from 'next/router';
import React from 'react';
import ControlButton, { ControlButtonProps } from 'src/components/ControlButton';
import { MobileMenuOption } from '../../../../../../options';

type MobileMenuItemProps = {
  option: MobileMenuOption;
} & ControlButtonProps;

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  option,
  paddingVertical = '12px',
  variant = 'Span16_26',
  leftSpacing = '12px',
  ...rest
}) => {
  const router = useRouter();
  const {
    translationKey,
    activeRoutePrefixes = [],
    dataAnalyticsId,
    NestedComponent,
    leftIcon,
    leftActiveIcon,
    hideExpandIcon
  } = option;
  const isActive = activeRoutePrefixes.some(prefix => router.route.startsWith(prefix));
  const showExpandIcon = Boolean(!hideExpandIcon && NestedComponent);
  return (
    <ControlButton
      key={translationKey}
      data-analytics-id={dataAnalyticsId}
      variant={variant}
      active={isActive}
      activeOnHover
      leftIcon={leftIcon}
      leftActiveIcon={leftActiveIcon}
      rightIcon={showExpandIcon ? 'semiArrowRightTinyGray' : undefined}
      rightActiveIcon={showExpandIcon ? 'semiArrowRightTinyPrimary' : undefined}
      rightSpacing={showExpandIcon ? 'xxsm' : undefined}
      paddingVertical={paddingVertical}
      leftSpacing={leftSpacing}
      href={option.link}
      target={option.target}
      external={option.external}
      {...rest}
    >
      <LocalizedText id={translationKey} />
    </ControlButton>
  );
};

export default MobileMenuItem;
