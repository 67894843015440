import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { TariffTaxesWidgetProps } from '../..';
import { SelectedProduct } from '../../../../redux/modules/product/interfaces';
import { MendelTaxesInfo } from '../../../../redux/modules/tariffTaxes/interfaces';
import { Flex, Text, Loader, SVGIcon, Tile } from '@components';
import TextIcon from '../../../../components/TextIcon';
import TooltipHelpText from '../../../../components/TooltipHelpText';
import TooltipSelect from '../../../../components/TooltipSelect';
import Styles from './styles';
import LocalizedText from 'src/components/LocalizedText';

const getProductCode = (product: SelectedProduct | null, selected: boolean): JSX.Element => {
  if (!product || !product.HSCode) return <></>;

  const code = `${product.HSCode.slice(0, 4)}.${product.HSCode.slice(-2)}`;
  return <Text.Span16_24_bold color={selected ? 'inherit' : 'onWhite'}>{code}</Text.Span16_24_bold>;
};

const getProductName = (product: SelectedProduct | null, maxSize = 80, incloudeProductCode = true): JSX.Element => {
  if (!product || !product.HSCode) return <></>;

  const code = `${product.HSCode.slice(0, 4)}.${product.HSCode.slice(-2)}`;
  let name = (incloudeProductCode ? `${code} ` : '') + `${product.description}`;

  if (name.length > maxSize) {
    name = name.slice(0, maxSize - 3) + '...';
  }

  return <Text.Span13_18_bold color="inherit">{name}</Text.Span13_18_bold>;
};

type ComponentProps = TariffTaxesWidgetProps & {
  selected?: MendelTaxesInfo | null;
  selectedProduct: SelectedProduct | null;
  setSelectedProduct: (product: SelectedProduct) => void;
  onMendelUrlClick: () => void;
};

const WidgetLoader = () => {
  return (
    <>
      <Loader width="100%" height="80px" marginBottom="default" />
      <Loader width="100%" height="100px" marginBottom="default" />
      <Loader width="100%" height="120px" marginBottom="default" />
    </>
  );
};

const Widget: React.FC<ComponentProps> = props => {
  const hasSpecificDuty =
    props?.selected?.duties?.specific &&
    (props.selected.duties.specific.min !== null || props.selected.duties.specific.max !== null);

  const hasAdvaloremDuty =
    props?.selected?.duties?.advalorem &&
    (props.selected.duties.advalorem.min !== null || props.selected.duties.advalorem.max !== null);

  const [dutyType, setDutyType] = useState(hasSpecificDuty ? 'specific' : 'advalorem');
  const [isTaxesOverflow, setIsTaxesOverflow] = useState(false);
  const [expandTaxes, setExpandTaxes] = useState(false);
  const expandableRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const element = expandableRef.current;
    if (element) {
      setIsTaxesOverflow(element.offsetHeight < element.scrollHeight);
    }
  }, [expandableRef.current]);

  useEffect(() => {
    setDutyType(hasAdvaloremDuty ? 'advalorem' : 'specific');
  }, [hasSpecificDuty, hasAdvaloremDuty]);

  if (!props.selected) return <WidgetLoader />;

  const hasDuty = hasSpecificDuty || hasAdvaloremDuty;
  const hasTaxes = !!props?.selected?.taxes?.taxes?.length;
  const mendelDataAvailable = props?.selected?.status != 'NOTFOUND';
  const fallbackMendelDetailsLink = `https://ch.mendel-online.com/query?o=CH&d=${props.market.ISOCode2Letter.toUpperCase()}`;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" marginBottom="xxsm" mobile>
        <Flex gap alignItems="center">
          <Text.SmallerParagraph weight="bold">
            <LocalizedText id="tariffAndTaxes.filter" description="Product label" />
          </Text.SmallerParagraph>
          <Styles.Filter>
            <TooltipSelect
              highlightItemsOnHover={true}
              highlightItemsTextOnHover={false}
              paddingOnlyInItems={true}
              standardColor="onWhiteLight"
              data-analytics-id="56-click-filter-tarif-and-taxes"
              options={props.products.map(product => {
                const selected = props.selectedProduct ? props.selectedProduct.HSCode === product.HSCode : false;
                return {
                  text: (
                    <Flex vertical gap="xxsm">
                      {getProductCode(product, selected)}
                      {getProductName(product, 80, false)}
                    </Flex>
                  ),
                  onClick: () => props.setSelectedProduct(product),
                  selected: selected
                };
              })}
            >
              {getProductName(props.selectedProduct, 80, true)}
            </TooltipSelect>
          </Styles.Filter>
        </Flex>

        {!props.loading && (
          <TextIcon
            color="onWhite"
            weight="bold"
            variant="SmallerParagraph"
            leftIconComponent={() => <img src="/static/icons/open.svg" />}
          >
            {mendelDataAvailable ? (
              <a
                href={props.selected.reportUrl}
                onAuxClick={props.onMendelUrlClick}
                onClick={props.onMendelUrlClick}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LocalizedText id="tariffAndTaxes.completeReportButton" description="Complete Report Button Label" />
              </a>
            ) : (
              <a href={fallbackMendelDetailsLink} target="_blank" rel="noopener noreferrer">
                <LocalizedText id="tariffAndTaxes.completeReportButton" description="Complete Report Button Label" />
              </a>
            )}
          </TextIcon>
        )}
      </Flex>
      {props.loading ? (
        <WidgetLoader />
      ) : (
        <>
          <Tile full marginBottom="sm" paddingHorizontal="xmd" paddingVertical="default">
            <Flex alignItems="center" justifyContent="space-between" mobile>
              <Text.H6>
                <LocalizedText id="tariffAndTaxes.freeAgreementTitle" description="Free Agreement Title" />
              </Text.H6>
              <TextIcon
                shouldHaveHover={false}
                color={props.selected.tradeAgreement.isAgreement ? 'onWhiteSuccess' : 'onWhiteError'}
                variant="H4"
                leftIconComponent={() =>
                  props.selected && props.selected.tradeAgreement.isAgreement ? (
                    <Styles.ImageContainer>
                      <img src="/static/icons/check-round.svg" />
                    </Styles.ImageContainer>
                  ) : (
                    <Styles.ImageContainer>
                      <img src="/static/icons/circleXred.svg" />
                    </Styles.ImageContainer>
                  )
                }
              >
                {props.selected.tradeAgreement.isAgreement ? (
                  <LocalizedText id="tariffAndTaxes.freeAgreementYes" description="Free Agreement Yes" />
                ) : props.selected.tradeAgreement.isAgreement === false ? (
                  <LocalizedText id="tariffAndTaxes.freeAgreementNo" description="Free Agreement No" />
                ) : (
                  <LocalizedText id="tariffAndTaxes.freeAgreementNA" description="Free Agreement N/A" />
                )}
              </TextIcon>
            </Flex>
          </Tile>
          {(hasDuty || !hasTaxes) && (
            <Tile full marginBottom="sm" paddingHorizontal="xmd" paddingTop="default" paddingBottom="none">
              <Flex alignItems="center" justifyContent="space-between" marginBottom="xmd" mobile>
                <TooltipHelpText variant="H6" contentTranslationKey="tariffAndTaxes.dutiesTitleTooltip">
                  <LocalizedText id="tariffAndTaxes.dutiesTitle" description="Duties title" />
                </TooltipHelpText>
                {hasDuty && (
                  <Flex gap>
                    <Text.SmallerParagraph weight="bold">
                      <LocalizedText id="tariffAndTaxes.dutyType" description="Duty Type" />:
                    </Text.SmallerParagraph>
                    {hasSpecificDuty && (
                      <Styles.Filter pointerCursor={dutyType !== 'specific'} onClick={() => setDutyType('specific')}>
                        <Text.SmallerParagraph
                          weight="bold"
                          color={dutyType === 'specific' ? 'onWhitePrimary' : 'onWhiteDark'}
                        >
                          <LocalizedText id="tariffAndTaxes.dutiesSpecific" description="Duties Specific" />
                        </Text.SmallerParagraph>
                      </Styles.Filter>
                    )}
                    {hasAdvaloremDuty && (
                      <Styles.Filter pointerCursor={dutyType !== 'advalorem'} onClick={() => setDutyType('advalorem')}>
                        <Text.SmallerParagraph
                          weight="bold"
                          color={dutyType === 'advalorem' ? 'onWhitePrimary' : 'onWhiteDark'}
                        >
                          <LocalizedText id="tariffAndTaxes.dutiesAdvalorem" description="Duties Advalorem" />
                        </Text.SmallerParagraph>
                      </Styles.Filter>
                    )}
                  </Flex>
                )}
              </Flex>
              {mendelDataAvailable ? (
                <>
                  {!hasDuty && !hasTaxes && (
                    <Flex vertical alignItems="center" paddingBottom="default">
                      <img src="/static/icons/circleX.svg" />
                      <Text.SmallParagraph color="onWhiteLight" marginTop="xsm">
                        <LocalizedText id="tariffAndTaxes.taxesNotAvailable" description="Full report button" />
                      </Text.SmallParagraph>
                    </Flex>
                  )}
                  {hasDuty &&
                    (props.selected.duties[dutyType].min === props.selected.duties[dutyType].max ? (
                      <Flex vertical alignItems="center" paddingBottom="default">
                        <Text.SmallParagraph>
                          <LocalizedText id="tariffAndTaxes.dutyValue" description="Value label" />
                        </Text.SmallParagraph>
                        <Text.H2 color="onWhitePrimary">{props.selected.duties[dutyType].max}</Text.H2>
                      </Flex>
                    ) : (
                      <Flex justifyContent="space-around" paddingBottom="default" mobile>
                        <Flex vertical alignItems="center">
                          <Text.SmallParagraph>
                            <LocalizedText id="tariffAndTaxes.dutiesMin" description="Minimum label" />
                          </Text.SmallParagraph>
                          <Text.H2 color="onWhitePrimary">{props.selected.duties[dutyType].min}</Text.H2>
                        </Flex>
                        <Flex vertical alignItems="center">
                          <Text.SmallParagraph>
                            <LocalizedText id="tariffAndTaxes.dutiesMax" description="Maximum label" />
                          </Text.SmallParagraph>
                          <Text.H2 color="onWhitePrimary">{props.selected.duties[dutyType].max}</Text.H2>
                        </Flex>
                      </Flex>
                    ))}
                  <Styles.Divider />
                  <Flex paddingVertical="xsm">
                    <TextIcon
                      variant="SmallParagraph"
                      leftIconComponent={() => <img src="/static/icons/knowledgePrimary.svg" />}
                    >
                      <Styles.ReportCaption>
                        <LocalizedText id="tariffAndTaxes.dutiesReportSuffix" description="Full report button suffix" />{' '}
                        <a
                          href={props.selected.reportUrl}
                          onAuxClick={props.onMendelUrlClick}
                          onClick={props.onMendelUrlClick}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LocalizedText id="tariffAndTaxes.dutiesReportButton" description="Full report button" />
                        </a>
                      </Styles.ReportCaption>
                    </TextIcon>
                  </Flex>
                </>
              ) : (
                <Flex vertical alignItems="center" paddingBottom="default">
                  <SVGIcon opacity={'0.8'} src="/static/icons/searchOnWhite.svg" paddingBottom="xsm" size={'24px'} />
                  <Text.SmallParagraph color="onWhiteSecondary" marginTop="xsm" paddingBottom="default">
                    <LocalizedText id="tariffAndTaxes.selectedHSCodeHasChanged" />{' '}
                    <Text.Link
                      highlightOnHover
                      highlightedByDefault
                      underlinedByDefault
                      href={fallbackMendelDetailsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LocalizedText id="tariffAndTaxes.accessInformationInDetailedView" />
                    </Text.Link>
                  </Text.SmallParagraph>
                </Flex>
              )}
            </Tile>
          )}
          {hasTaxes && (
            <Tile full paddingHorizontal="xmd" paddingTop="default" paddingBottom="none">
              <Flex alignItems="center" justifyContent="space-between" marginBottom="xmd">
                <TooltipHelpText variant="H6" contentTranslationKey="tariffAndTaxes.taxesTooltip">
                  <LocalizedText id="tariffAndTaxes.taxesTitle" description="Taxes title" />
                </TooltipHelpText>
              </Flex>
              <Styles.Expandable open={expandTaxes} ref={expandableRef}>
                {props.selected.taxes.taxes.map(tax => {
                  return (
                    <div key={tax.abrev}>
                      <Text.SmallParagraph size="16px" weight="bold" marginBottom="sm">
                        {tax.label}
                      </Text.SmallParagraph>
                      <Text.SmallParagraph color="onWhiteLight">{parse(tax.note || '')}</Text.SmallParagraph>
                    </div>
                  );
                })}
              </Styles.Expandable>
              {isTaxesOverflow && !expandTaxes ? (
                <Flex full vertical alignItems="center" onClick={() => setExpandTaxes(true)} paddingVertical="xsm">
                  <TextIcon
                    color="onWhitePrimary"
                    weight="bold"
                    variant="SmallParagraph"
                    rightIconComponent={() => <img src="/static/icons/semiArrowDownTinyPrimary.svg" />}
                  >
                    <Styles.Filter>
                      <LocalizedText id="seeMore" description="Full report button" />
                    </Styles.Filter>
                  </TextIcon>
                </Flex>
              ) : (
                <Flex paddingBottom="default" />
              )}
            </Tile>
          )}
        </>
      )}
    </>
  );
};

export default Widget;
