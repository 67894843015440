import { LocalizedText, AppLink, Flex, TileProps } from '@components';
import React from 'react';
import Styles from './styles';
import SharedStyles from '../../../../styles';
import { WebMenuOption } from '../../../../../../options';
import { useRouter } from 'next/router';
import { useDetectClickOutside } from 'src/hooks/useDetectClickOutside';
import { DynamicWrapper } from '@utils/reactUtils';

type WebMenuItemProps = React.PropsWithChildren<
  {
    option: WebMenuOption;
    isOpen?: boolean;
    href?: string;
    //In case href is provided, handleClick and handleClose are ignored
    handleClick?(): void;
    handleClose?(): void;
    isLightUser?: boolean;
  } & TileProps
>;

const WebMenuItem: React.FC<WebMenuItemProps> = ({
  option,
  handleClick,
  handleClose,
  isOpen,
  isLightUser,
  children,
  ...rest
}) => {
  const router = useRouter();

  React.useEffect(() => {
    handleClose?.();
  }, [router.asPath]);

  const outisideClickRef = useDetectClickOutside({
    onTriggered: handleClose
  });

  const { translationKey, activeRoutePrefixes = [], dataAnalyticsId } = option;
  const isActive = activeRoutePrefixes.some(prefix => router.route.startsWith(prefix));
  return (
    <Flex data-analytics-id={dataAnalyticsId} fullHeight ref={isOpen ? outisideClickRef : null}>
      {DynamicWrapper(
        children =>
          option.link ? (
            <AppLink href={option.link} target={option.target}>
              {children}
            </AppLink>
          ) : (
            <React.Fragment>{children}</React.Fragment>
          ),
        <Styles.Container
          active={isActive}
          alignItems="center"
          justifyContent="center"
          variant="Span16_24_bold"
          activeOnHover
          onClick={rest.href ? undefined : handleClick}
          isOpen={isOpen}
          isLightUser={isLightUser}
        >
          <LocalizedText id={translationKey} />
        </Styles.Container>
      )}

      <SharedStyles.DropdownContent variant="raised" fullWidth isOpen={isOpen} {...rest}>
        {children}
      </SharedStyles.DropdownContent>
    </Flex>
  );
};

export default WebMenuItem;
