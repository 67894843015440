import {
  RETRIEVE_ALL_CACHED_MARKETS,
  RetrieveAllCachedMarketsAction,
  RetrieveAllCachedMarketsSuccessAction,
  RetrieveAllCachedMarketsFailureAction,
  RETRIEVE_ALL_CACHED_MARKETS_FAILURE,
  RETRIEVE_ALL_CACHED_MARKETS_SUCCESS
} from './types';

export const doRetrieveAllCachedMarkets = (): RetrieveAllCachedMarketsAction => ({
  type: RETRIEVE_ALL_CACHED_MARKETS
});

export const doRetrieveAllCachedMarketsSuccess = (
  marketList: RetrieveAllCachedMarketsSuccessAction['marketList']
): RetrieveAllCachedMarketsSuccessAction => ({
  type: RETRIEVE_ALL_CACHED_MARKETS_SUCCESS,
  marketList
});

export const doRetrieveAllCachedMarketsFailure = (
  error: RetrieveAllCachedMarketsFailureAction['error']
): RetrieveAllCachedMarketsFailureAction => ({
  type: RETRIEVE_ALL_CACHED_MARKETS_FAILURE,
  error
});
