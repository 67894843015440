import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../../redux/reducers';
import ProductPotential from '../../../components/Products/UserProducts';
import {
  doRetrieveProducts,
  doRetrieveProductsFull,
  doSaveProducts,
  doChangeSearch
} from './../../../redux/modules/product/actions';
import { MarketProductPotential } from '../../../redux/modules/marketGuide/interfaces';

export type UserProductPotentialProps = ReducerProps & TemplateProps;

type TemplateProps = {
  hidePotential?: boolean;
  profile?: boolean;
  tocGutter?: boolean;
  showMarketPotential?: boolean;
  marketProductPotential?: MarketProductPotential[];
  marketProductPotentialLoading?: boolean;
};

const UserProducts: React.FC<UserProductPotentialProps> = props => <ProductPotential {...props} />;

const mapStateToProps = ({ productReducer, marketGuideReducer }: AppState) => ({
  products: productReducer.products,
  selectedProducts: productReducer.selected,
  loadingProducts: productReducer.loading,
  errorProducts: productReducer.error,
  marketProductPotential: marketGuideReducer.marketProductPotential,
  marketProductPotentialLoading: marketGuideReducer.marketProductPotentialLoading
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doRetrieveProducts,
      doRetrieveProductsFull,
      doSaveProducts,
      doChangeSearch
    },
    dispatch
  );

type ReducerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserProducts);
