import styled from 'styled-components';
import { LaptopAndUp } from 'src/components/stylingUtils';
import { TabletAndDown } from '../../../../../components/stylingUtils';

export default {
  Container: styled.div<{ small?: boolean }>`
    display: inline-block;
    width: 378px;

    ${props => LaptopAndUp(props)} {
      width: ${props => (props.small ? '378px' : '100%')};
    }

    > * {
      height: 100%;
      min-height: 359px;
    }

    ${props => TabletAndDown(props)} {
      :last-child {
        margin-right: ${props => props.theme.spacing.lg};
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.small}) {
      :last-child {
        margin-right: ${props => props.theme.spacing.default};
      }
    }
  `,
  Content: styled.div<{ small?: boolean }>`
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    ${props => LaptopAndUp(props)} {
      flex-direction: ${props => (props.small ? 'column-reverse' : 'row')};
    }
  `,
  IconWrapper: styled.div`
    text-align: left;
  `,
  ImageContainer: styled.div`
    background-image: url('/static/img/consultant-card.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 360px;
  `,
  InfoWrapper: styled.div<{ small?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 42px;

    ${props => (props.small ? 'flex: 1;' : 'flex-basis: 30%;')}
  `,
  VideoWrapper: styled.div<{ small?: boolean }>`
    ${props => (props.small ? '' : 'flex-basis: 70%;')}
  `
};
