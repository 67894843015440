import styled from 'styled-components';

const ResponsiveIframe = styled.div`
  position: relative;
  padding-bottom: 53.25%; /* 16:9 */

  iframe {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default ResponsiveIframe;
