import { Flex } from '@components';
import { user2BusinessType } from '@utils/userUtils';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from 'src/redux/reducers';
import MobileMenuItem from '../../shared/MobileMenuItem';

const NestedExpertMenu: React.FC<NestedExpertMenuReduxProps> = ({ user, practicalExportGuideReportUuid }) => {
  const intl = useIntl();
  const isServiceBusinessType = user2BusinessType(user) === 'service';
  return (
    <Flex vertical>
      <MobileMenuItem
        option={{
          translationKey: 'menus.postingOfEmployees',
          dataAnalyticsId: '99-posting-of-employees',
          leftIcon: 'userSend',
          leftActiveIcon: 'userSendPrimary',
          link: 'posting-of-employees'
        }}
      />
      {!isServiceBusinessType && (
        <MobileMenuItem
          option={{
            translationKey: 'menus.exportNavigator',
            leftIcon: 'exportGuide',
            leftActiveIcon: 'exportGuidePrimary',
            link: `export-guide${practicalExportGuideReportUuid ? `/report/${practicalExportGuideReportUuid}` : '/new'}`
          }}
        />
      )}
      <MobileMenuItem
        option={{
          translationKey: 'menus.allArticles',
          leftIcon: 'list',
          leftActiveIcon: 'listPrimary',
          link: 'articles'
        }}
      />
      <MobileMenuItem
        option={{
          translationKey: 'menus.expertDirectory',
          leftIcon: 'experts',
          leftActiveIcon: 'expertsPrimary',
          link: intl.formatMessage({ id: 'menus.expertDirectoryLink' }),
          target: '_blank',
          external: true
        }}
      />
      {!isServiceBusinessType && (
        <MobileMenuItem
          option={{
            translationKey: 'menus.mendelOnline',
            leftIcon: 'file-invoice-dollar',
            leftActiveIcon: 'file-invoice-dollarPrimary',
            link: intl.formatMessage({ id: 'menus.mendelLink' }),
            target: '_blank',
            external: true
          }}
        />
      )}
    </Flex>
  );
};

const mapStateToProps = ({ userReducer, reportReducer }: AppState) => ({
  user: userReducer.user,
  practicalExportGuideReportUuid: reportReducer.uuid
});

export type NestedExpertMenuReduxProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(NestedExpertMenu);
