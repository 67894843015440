import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  GenericError,
  LocalizedText,
  MissingCountry,
  ControlButton,
  Button,
  Flex,
  Spacing,
  Spinner,
  SVGIcon
} from '@components';
import { doRetrieveAllCachedMarkets } from '@redux-modules/cachedMarketList/actions';
import { CachedMarket } from '@redux-modules/cachedMarketList/types';
import { AppState } from 'src/redux/reducers';
import Styles from './styles';
import SharedStyles from '../../components/WebMenu/styles';

const filterMarkets = (markets: CachedMarket[], filterText: string) => {
  return markets
    .filter(m => m.ISOCode2Letter != 'RU')
    .filter(m => filterText == '' || m.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
};

type MarketMenuContentProps = {
  handleClose?(): void;
  isOpen: boolean;
};

const MarketMenuContent: React.FC<MarketMenuContentProps & MarketMenuContentReduxProps> = ({
  marketsList,
  marketsListError,
  marketsListLoading,
  doRetrieveAllCachedMarkets,
  handleClose,
  isOpen
}) => {
  const intl = useIntl();

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const [optionsFilterText, setOptionsFilterText] = React.useState('');
  const onInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = (event && event.key) || null;
    if (key && key === 'Escape') {
      if (!optionsFilterText) {
        handleClose?.();
      } else {
        setOptionsFilterText('');
      }
    }
  };
  const onSearchInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event && event.target && event.target.value) || '';
    setOptionsFilterText(value);
  };

  return (
    <Flex fullWidth vertical>
      <Flex fullWidth justifyContent="space-between" alignItems="center">
        <Flex justifyContent="start">
          <Styles.PlaceholderIconContainer>
            <SVGIcon src="/static/icons/searchOnWhite.svg" size="16px" />
          </Styles.PlaceholderIconContainer>
          <Styles.InputText
            placeholder={intl.formatMessage({ id: 'menus.exploreMarkets.missingCountry.searchPlaceholder' })}
            onKeyUp={onInputKeyUp}
            value={optionsFilterText}
            onChange={onSearchInputTextChange}
            fontSize={'16px'}
            textColor={'onWhite'}
            borderColor={'onWhite'}
            autoComplete="off"
            ref={inputRef}
          />
        </Flex>
        {handleClose && (
          <SharedStyles.CloseButton onClick={handleClose}>
            <img src="/static/icons/cross.svg" />
          </SharedStyles.CloseButton>
        )}
      </Flex>
      <Styles.MarketsGrid>
        {marketsListError ? (
          <>
            <GenericError error={marketsListError} />
            <Flex marginVertical="default" alignItems="center" justifyContent="center">
              <Button.xxLarge onClick={doRetrieveAllCachedMarkets}>
                <LocalizedText id="tryAgain" description="Try again button" />
              </Button.xxLarge>
            </Flex>
          </>
        ) : marketsListLoading ? (
          <Spinner.Default />
        ) : (
          <>
            {filterMarkets(marketsList, optionsFilterText)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({ slug, name }) => (
                <ControlButton activeOnHover variant="Span18_22" href={`markets/${slug}#about`} key={slug}>
                  {name}
                </ControlButton>
              ))}

            <Styles.MissingCountryWrapper>
              <MissingCountry
                emailTranslationKey="menus.exploreMarkets.missingCountry.email"
                missingCountryTranslationKey="menus.exploreMarkets.missingCountry.text"
                letUsKnowTranslationKey="menus.exploreMarkets.missingCountry.letUsKnow"
              />
            </Styles.MissingCountryWrapper>
          </>
        )}
      </Styles.MarketsGrid>
      <Spacing marginTop="default" paddingLeft="sm" />
    </Flex>
  );
};

const mapStateToProps = ({ cachedMarketListReducer }: AppState) => ({
  marketsList: cachedMarketListReducer.cachedMarketList,
  marketsListLoading: cachedMarketListReducer.loading,
  marketsListError: cachedMarketListReducer.error
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doRetrieveAllCachedMarkets
    },
    dispatch
  );

export type MarketMenuContentReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MarketMenuContent);
