import { LocalizedText, Modal, Breakpoints, Flex, Text, LinkButton } from '@components';
import getConfig from 'next/config';
import Link from 'next/link';
import React, { FC, useState } from 'react';
import { useLocale } from 'src/locales';
import Styles from './styles';

const CTAButton: FC = () => {
  const locale = useLocale();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Styles.CTAButton
        onClick={() => setIsModalOpen(true)}
        data-analytics-id="79-product-exporter"
        data-test-id="cta-button"
      >
        <Breakpoints.TabletAndDown>
          <Styles.TitleWithIconTablet as="span" weight="bold" color={isModalOpen ? 'onWhitePrimary' : undefined}>
            <LocalizedText id="menus.cta.title" description="CTA signup title" />
          </Styles.TitleWithIconTablet>
          <Text.SmallParagraph color={isModalOpen ? 'onWhitePrimary' : 'onWhiteLight'}>
            <LocalizedText id="menus.cta.text" description="CTA signup text" />
          </Text.SmallParagraph>
        </Breakpoints.TabletAndDown>
        <Breakpoints.LaptopAndUp>
          <Styles.TitleWithIconLaptop as="span" weight="bold" color={isModalOpen ? 'onWhitePrimary' : undefined}>
            <LocalizedText id="menus.cta.title" description="CTA signup title" />
          </Styles.TitleWithIconLaptop>
          <Text.SmallerParagraph color={isModalOpen ? 'onWhitePrimary' : 'onWhiteLight'}>
            <LocalizedText id="menus.cta.text" description="CTA signup text" />
          </Text.SmallerParagraph>
        </Breakpoints.LaptopAndUp>
      </Styles.CTAButton>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} width="600px">
        <Flex vertical paddingVertical="md" paddingHorizontal="sm">
          <Text.H2Responsive align="center" marginBottom="md">
            <LocalizedText id="signup.modal.title" description="Unlock personalized cockpit title" />
          </Text.H2Responsive>
          <Text.ParagraphResponsive>
            <LocalizedText id="signup.modal.text" description="Unlock personalized cockpit text" />
          </Text.ParagraphResponsive>
          <Flex mobile gap="default" alignItems="center" justifyContent="center" marginTop="md">
            <LinkButton.Medium
              variant="dark"
              href={`${getConfig().publicRuntimeConfig.CMS_URL}${locale}/node/104546`}
              target="_blank"
              data-analytics-id="80-read-more-about-goglobal-cockpit"
            >
              <LocalizedText id="signup.modal.readMore" description="Read more button" />
            </LinkButton.Medium>
            <Link href={`/${locale}/signup/about`}>
              <LinkButton.Medium data-analytics-id="80-start-signup">
                <LocalizedText id="signup.modal.startNow" description="Start now button" />
              </LinkButton.Medium>
            </Link>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default CTAButton;
