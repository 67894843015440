import {
  RETRIEVE_PRODUCT_TAXES,
  RETRIEVE_PRODUCT_TAXES_SUCCESS,
  RETRIEVE_PRODUCT_TAXES_FAILURE,
  RetrieveProductTaxesAction,
  RetrieveProductTaxesSuccessAction,
  RetrieveProductTaxesFailureAction
} from './types';

export const doRetrieveProductTaxes = (
  product: RetrieveProductTaxesAction['product'],
  market: RetrieveProductTaxesAction['market']
): RetrieveProductTaxesAction => ({
  type: RETRIEVE_PRODUCT_TAXES,
  product,
  market
});

export const doRetrieveProductTaxesSuccess = (
  productTaxes: RetrieveProductTaxesSuccessAction['productTaxes']
): RetrieveProductTaxesSuccessAction => ({
  type: RETRIEVE_PRODUCT_TAXES_SUCCESS,
  productTaxes
});

export const doRetrieveProductTaxesFailure = (
  error?: RetrieveProductTaxesFailureAction['error']
): RetrieveProductTaxesFailureAction => ({
  type: RETRIEVE_PRODUCT_TAXES_FAILURE,
  error
});
