import { RequestResult } from '../../../interfaces/requests';
import { MendelTaxesInfo } from './interfaces';
import { SelectedProduct } from '../product/interfaces';
import { MarketGuide } from '../marketGuide/interfaces';

export const RETRIEVE_PRODUCT_TAXES = 'tariffTaxes/RETRIEVE_PRODUCT_TAXES';
export const RETRIEVE_PRODUCT_TAXES_SUCCESS = 'tariffTaxes/RETRIEVE_PRODUCT_TAXES_SUCCESS';
export const RETRIEVE_PRODUCT_TAXES_FAILURE = 'tariffTaxes/RETRIEVE_PRODUCT_TAXES_FAILURE';

export interface RetrieveProductTaxesAction {
  type: typeof RETRIEVE_PRODUCT_TAXES;
  product: SelectedProduct;
  market: MarketGuide;
}
export interface RetrieveProductTaxesSuccessAction {
  type: typeof RETRIEVE_PRODUCT_TAXES_SUCCESS;
  productTaxes: MendelTaxesInfo;
}
export interface RetrieveProductTaxesFailureAction {
  type: typeof RETRIEVE_PRODUCT_TAXES_FAILURE;
  error?: RequestResult<void>;
}
