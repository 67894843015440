import { LocalizedText, ControlButton, Flex, Text } from '@components';
import { CMSArticle } from '@redux-modules/article/interfaces';
import React from 'react';
import SharedStyles from '../../../../styles';
import Styles from './styles';

const ArticlePreview: React.FC<{ article: CMSArticle }> = ({ article }) => {
  const { title, type, image, drupalNodeId } = article;
  const articleLink = `article/${drupalNodeId}`;
  return (
    <Styles.ArticleContainer href={articleLink} data-analytics-id="141-click-article-link">
      <Flex gap="default">
        <Styles.ImageContainer>
          <SharedStyles.PreviewImage src={image?.teaserMd} data-transform="article-image" />
        </Styles.ImageContainer>
        <Flex vertical gap="xsm">
          <Text.Span18_28_bold>{title}</Text.Span18_28_bold>
          <Text.Span14_22>{type}</Text.Span14_22>
          <SharedStyles.Click>
            <ControlButton rightIcon="semiArrowRightTinyPrimary" active variant="Span13_18_bold" rightSpacing="xxsm">
              <LocalizedText id="menus.readFullArticle" />
            </ControlButton>
          </SharedStyles.Click>
        </Flex>
      </Flex>
    </Styles.ArticleContainer>
  );
};

export default ArticlePreview;
