import { helpers } from '@components';
import styled from 'styled-components';

export default {
  Filter: styled.span<{ pointerCursor?: boolean }>`
    ${props => (props.pointerCursor !== false ? 'cursor: pointer;' : '')}
    ${helpers}

    &:hover p {
      color: ${props => props.theme.colors.text.onWhitePrimary};
    }
  `,
  ImageContainer: styled.div`
    width: 30px;
    height: 30px;
    img {
      width: 100%;
      object-fit: cover;
      transition: transform 100ms ease-out;
    }
  `,
  Divider: styled.div`
    background-color: ${props => props.theme.colors.disabled};
    height: 1px;
    width: 100%;
    min-height: 1px;
  `,
  ReportCaption: styled.p`
    margin: 0;
    a {
      color: ${props => props.theme.colors.primary};
      text-decoration: underline;
      :hover {
        cursor: pointer;
      }
    }
  `,
  Expandable: styled.div<{ open: boolean }>`
    max-height: ${props => (props.open ? 'initial' : '280px')};
    overflow: hidden;
  `
};
