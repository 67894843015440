import { LocalizedText, AppLink, Flex, Spinner, Text } from '@components';
import { user2BusinessType } from '@utils/userUtils';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from 'src/redux/reducers';
import SharedStyles from '../../../../styles';
import ArticlePreview from '../ArticlePreview';

const ExpertMenuContent: React.FC<ExpertMenuContentReduxProps & { handleClose: () => void }> = ({
  user,
  practicalExportGuideReportUuid,
  articles = [],
  loading,
  handleClose
}) => {
  const intl = useIntl();
  const isServiceBusinessType = user2BusinessType(user) === 'service';
  return (
    <>
      <Flex justifyContent="center">
        <SharedStyles.MenuColumn>
          <Text.Span22_22_bold marginBottom="md">
            <LocalizedText id="menus.exportInformation" />
          </Text.Span22_22_bold>
          <Flex vertical gap="default">
            <AppLink href="posting-of-employees">
              <SharedStyles.MenuLink data-analytics-id="99-posting-of-employees">
                <LocalizedText id="menus.postingOfEmployees" />
              </SharedStyles.MenuLink>
            </AppLink>
            {!isServiceBusinessType && (
              <AppLink
                href={`export-guide${
                  practicalExportGuideReportUuid ? `/report/${practicalExportGuideReportUuid}` : '/new'
                }`}
              >
                <SharedStyles.MenuLink>
                  <LocalizedText id="menus.exportNavigator" />
                </SharedStyles.MenuLink>
              </AppLink>
            )}
            <AppLink href="articles">
              <SharedStyles.MenuLink>
                <LocalizedText id="menus.allArticles" />
              </SharedStyles.MenuLink>
            </AppLink>
            <AppLink href={intl.formatMessage({ id: 'menus.expertDirectoryLink' })} target="_blank" external>
              <SharedStyles.MenuLink>
                <LocalizedText id="menus.expertDirectory" />
              </SharedStyles.MenuLink>
            </AppLink>
            {!isServiceBusinessType && (
              <AppLink href={intl.formatMessage({ id: 'menus.mendelLink' })} target="_blank" external>
                <SharedStyles.MenuLink>
                  <LocalizedText id="menus.mendelOnline" />
                </SharedStyles.MenuLink>
              </AppLink>
            )}
          </Flex>
        </SharedStyles.MenuColumn>
        <SharedStyles.Delimiter />
        <SharedStyles.ContentColumn>
          <Text.Span22_22_bold marginBottom="md">
            <LocalizedText id="menus.popularArticles" />
          </Text.Span22_22_bold>
          <Flex vertical gap="default" fullHeight>
            {loading ? (
              <Spinner.Default />
            ) : (
              articles.slice(0, 2).map((article, index) => <ArticlePreview article={article} key={index} />)
            )}
          </Flex>
        </SharedStyles.ContentColumn>
      </Flex>
      <SharedStyles.CloseButtonContainer>
        <SharedStyles.CloseButton onClick={handleClose}>
          <img src="/static/icons/cross.svg" />
        </SharedStyles.CloseButton>
      </SharedStyles.CloseButtonContainer>
    </>
  );
};

const mapStateToProps = ({ articleReducer, userReducer, reportReducer }: AppState) => ({
  articles: articleReducer.menuArticles,
  user: userReducer.user,
  practicalExportGuideReportUuid: reportReducer.uuid,
  loading: articleReducer.menuArticlesLoading
});

export type ExpertMenuContentReduxProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(ExpertMenuContent);
