import styled, { css } from 'styled-components';
import { Text } from '@components';

const arrowIconAfter = css`
  content: '';
  display: block;
  position: absolute;
  background: url('/static/icons/semiArrowRightTiny.svg') center/cover no-repeat;
`;

export default {
  CTAButton: styled.button`
    outline: none;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    padding: 0;
    margin: ${props => props.theme.spacing.default} 0;

    &:hover {
      * {
        color: ${props => props.theme.colors.text.onWhitePrimary} !important;
        text-decoration: none;
        &:first-child:after {
          background: url('/static/icons/semiArrowRightTinyPrimary.svg') center/cover no-repeat;
        }
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.large}) {
      margin: 0;
    }
  `,
  TitleWithIconTablet: styled(Text.SmallParagraph)`
    position: relative;
    &:after {
      ${arrowIconAfter}
      width: 18px;
      height: 18px;
      right: -${props => props.theme.spacing.default};
      top: 1px;
    }
  `,
  TitleWithIconLaptop: styled(Text.SmallerParagraph)`
    position: relative;
    &:after {
      ${arrowIconAfter}
      width: 14px;
      height: 14px;
      right: -${props => props.theme.spacing.sm};
      top: 1px;
    }
  `
};
