import React from 'react';

import { useIntl } from 'react-intl';
import { AppState } from 'src/redux/reducers';
import { connect } from 'react-redux';
import SharedStyles from '../../styles';
import MobileMenuItem from '../../shared/MobileMenuItem';
import { AppLink, Flex, LocalizedText } from '@components';

const NestedEventMenu: React.FC<NestedEventMenuReduxProps> = ({ user }) => {
  const intl = useIntl();
  return (
    <Flex vertical>
      {!!user?.CRMGuid && (
        <MobileMenuItem
          option={{
            translationKey: 'menus.myEvents',
            leftIcon: 'calendar-smallOnWhiteSecondary',
            leftActiveIcon: 'calendar-smallPrimary',
            link: 'my-events',
            dataAnalyticsId: '140-my-events-big-menu'
          }}
        />
      )}
      <MobileMenuItem
        option={{
          translationKey: 'menus.relevantEvents',
          leftIcon: 'calendar-smallOnWhiteSecondary',
          leftActiveIcon: 'calendar-smallPrimary',
          link: 'events'
        }}
      />
      <AppLink href={intl.formatMessage({ id: 'menus.tradeForumLink' })}>
        <SharedStyles.NestedControlButton leftIcon="world" leftActiveIcon="worldPrimary">
          <LocalizedText id="menus.backToGrowth" />
        </SharedStyles.NestedControlButton>
      </AppLink>
    </Flex>
  );
};

const mapStateToProps = ({ userReducer }: AppState) => ({
  user: userReducer.user
});

export type NestedEventMenuReduxProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(NestedEventMenu);
