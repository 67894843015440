import React from 'react';
import moment from 'moment-timezone';
import LocalizedText from 'src/components/LocalizedText';
import { OECVisualizerProps } from '.';
import { Flex, Text, TooltipSelect } from '@components';
import { useVisualizer } from './context';
import Styles from './styles';
import { PublishOnBackendQueueAction } from 'src/redux/modules/queueing/types';
import { SelectedProduct } from '@redux-modules/product/interfaces';
import { User } from '@redux-modules/user/interfaces';

const getProductCode = (product: SelectedProduct | null, selected: boolean): JSX.Element => {
  if (!product || !product.HSCode) return <></>;
  const code = `${product.HSCode.slice(0, 4)}.${product.HSCode.slice(-2)}`;

  return <Text.Span16_24_bold color={selected ? 'inherit' : 'onWhite'}>{code}</Text.Span16_24_bold>;
};
const getProductName = (product: SelectedProduct | null, maxSize = 300, incloudeProductCode = true): JSX.Element => {
  if (!product || !product.HSCode) return <></>;
  const code = `${product.HSCode.slice(0, 4)}.${product.HSCode.slice(-2)}`;

  let name = (incloudeProductCode ? `${code} ` : '') + `${product.description}`;

  if (name.length > maxSize) {
    name = name.slice(0, maxSize - 3) + '...';
  }

  return <Text.Span13_18_bold color="inherit">{name}</Text.Span13_18_bold>;
};

const ProductSelect: React.FC<
  Pick<OECVisualizerProps, 'products' | 'isDashboard'> & {
    user?: User;
    doPublishOnBackendQueue?: (
      event: PublishOnBackendQueueAction['event'],
      variables: { userId?: number; startDate?: string }
    ) => void;
  }
> = props => {
  const { selectedProduct, setSelectedProduct, isFullscreen, hasProvider } = useVisualizer();

  if (!hasProvider) {
    throw 'Called ProductSelect. But no VisualizerProvider was found. Wrap your ProductSelect with the same VisualizerProvider you wrapped the OECVisualizerContainer or OECVisualizer component';
  }

  if (!props.products || props.products.length < 1) return null;
  if (!selectedProduct) return null;

  let maxProductNameSize = 40;
  if (isFullscreen) {
    maxProductNameSize = 120;
  }

  let maxProductNameSize_Item = 130;
  if (isFullscreen) {
    maxProductNameSize_Item = 120;
  }

  const onVisualizerClick = (product: SelectedProduct) => {
    if (props.user && props.doPublishOnBackendQueue && props.isDashboard) {
      //Only send tracking if the user changes products
      if (selectedProduct.HSCode !== product.HSCode) {
        props.doPublishOnBackendQueue('clicked_on_visualizer', {
          userId: props.user.id,
          startDate: moment.tz('Europe/Zurich').format('MM/DD/YYYY HH:mm:ss')
        });
      }
    }
    // always set the selected product
    setSelectedProduct(product);
  };

  return (
    <Flex gap>
      {!isFullscreen ? (
        <>
          <Text.SmallerParagraph weight="bold">
            <LocalizedText id="visualizerProductLabel" description="Product label" />
          </Text.SmallerParagraph>
          <Styles.Filter disabled={false}>
            <TooltipSelect
              standardColor="onWhiteLight"
              highlightItemsOnHover={true}
              highlightItemsTextOnHover={false}
              paddingOnlyInItems={true}
              data-analytics-id="30-importing-countries-click-filter-oec"
              options={props.products.map(product => {
                const selected = selectedProduct ? selectedProduct.HSCode === product.HSCode : false;
                return {
                  text: (
                    <Flex vertical gap="xxsm">
                      {getProductCode(product, selected)}
                      {getProductName(product, maxProductNameSize_Item, false)}
                    </Flex>
                  ),
                  onClick: () => onVisualizerClick(product),
                  selected: selected
                };
              })}
            >
              {getProductName(selectedProduct, maxProductNameSize, true)}
            </TooltipSelect>
          </Styles.Filter>
        </>
      ) : (
        <>
          <Text.SmallerParagraph weight="bold">
            <LocalizedText id="visualizerProductLabel" description="Product label" />{' '}
          </Text.SmallerParagraph>
          <Text.SmallerParagraph weight="bold" color="onWhitePrimary">
            {getProductName(selectedProduct, maxProductNameSize, true)}
          </Text.SmallerParagraph>
        </>
      )}
    </Flex>
  );
};

export default ProductSelect;
