import { Tile } from '@components';
import { MobileAndDown, TabletAndDown } from 'src/components/stylingUtils';
import styled from 'styled-components';

export default {
  Nav: styled.nav`
    height: 90px;
    background-color: #ffffff;
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 1%);

    ${props => TabletAndDown(props)} {
      height: 76px;
    }
  `,
  Container: styled(Tile)`
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 ${props => props.theme.spacing.md};
    position: relative;

    ${props => TabletAndDown(props)} {
      padding: 0 ${props => props.theme.spacing.default};
    }
  `,
  LogoImage: styled.img`
    position: absolute;
    padding-left: inherit;
    left: 0;
  `,
  DropdownContent: styled(Tile)<{ isOpen?: boolean }>`
    position: absolute;
    z-index: -1;
    pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
    a {
      text-decoration: none;
    }
    top: 100%;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    transform: translate(-50%);
    transition: opacity 250ms cubic-bezier(0.33, 0.7, 0.32, 1) 0ms;
    opacity: ${props => (props.isOpen ? 1 : 0)};
    border-top: 2px solid ${props => props.theme.colors.background};

    ${props => TabletAndDown(props)} {
      padding: 16px 24px;
      overflow: auto;

      ${props => props.isOpen && 'max-height: calc(100vh - 116px);'}
    }
    ${props => MobileAndDown(props)} {
      ${props => props.isOpen && 'height: calc(100vh - 116px);'}
    }
  `
};
