import { AppLink, Flex } from '@components';
import styled from 'styled-components';

export default {
  DateContainer: styled(Flex)`
    position: absolute;
    top: 0;
    padding: 12px 24px;
    width: 80px;
    height: 75px;
  `,
  DateHead: styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text.onPrimary};
    text-align: center;
    font-weight: 400;
    font-size: 6px;
    line-height: 11px;
    padding: 2px 0;
  `,
  DateBody: styled(Flex)`
    background-color: ${props => props.theme.colors.background};
  `,
  EventContainer: styled(AppLink)`
    display: flex;
    > * {
      cursor: pointer !important;
    }

    &&:not(:last-child) {
      margin-bottom: ${props => props.theme.spacing.default};
    }
    @media (hover: hover) {
      &:hover {
        ${'[data-transform="event-image"]'} {
          transform: scale(1.05);
        }

        a > div {
          text-decoration: underline;
          text-decoration-color: ${props => props.theme.colors.error};
        }
      }
    }
  `,
  RelativeDiv: styled.div`
    margin-right: ${props => props.theme.spacing.default};
    position: relative;
    overflow: hidden;
    min-width: 130px;
    height: 100px;
  `
};
