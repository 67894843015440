import React from 'react';
import { LocalizedText, Flex } from '@components';
import Styles from './styles';
import { useBreakpoints } from '@utils/viewportUtils';
import getConfig from 'next/config';
import { useLocale } from 'src/locales';

const ExternalLinks: React.FC = () => {
  const { tabletAndDown, mobile } = useBreakpoints();
  const locale = useLocale();
  const { publicRuntimeConfig } = getConfig();
  const websiteUrl = publicRuntimeConfig.SGE_WEBSITE_URL || 'https://test.s-ge.com';
  const exportWebsite = `${websiteUrl}/${locale}`;
  const investWebsite = `${websiteUrl}/${locale}/sbh`;
  return (
    <Flex fullHeight fullWidth={mobile}>
      <Styles.ExternalLinkContainer external target="_self" href={exportWebsite}>
        <Styles.ExternalLinkText>
          <LocalizedText id={tabletAndDown ? 'globalNav.exportWebsiteShort' : 'globalNav.exportWebsite'} />
        </Styles.ExternalLinkText>
      </Styles.ExternalLinkContainer>
      <Styles.ExternalLinkContainer external target="_self" href={investWebsite}>
        <Styles.ExternalLinkText>
          <LocalizedText id={tabletAndDown ? 'globalNav.investWebsiteShort' : 'globalNav.investWebsite'} />
        </Styles.ExternalLinkText>
      </Styles.ExternalLinkContainer>
      <Styles.EmptyMenuContainer selected>
        <Styles.ExternalLinkText weight={700}>
          <LocalizedText id="title" />
        </Styles.ExternalLinkText>
      </Styles.EmptyMenuContainer>
    </Flex>
  );
};

export default React.memo(ExternalLinks);
