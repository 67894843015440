import React from 'react';
import LocalizedText from 'src/components/LocalizedText';

import TooltipHelpText from 'src/components/TooltipHelpText';
import DashboardStyles from 'src/modules/dashboard/styles';
import Container from 'src/components/Container';
import Flex from 'src/components/Flex';
import OurOfferingsCards from './components/OurOfferingsCards';

interface OurOfferingsCardsSectionProps {
  country?: string;
  packages: any[];
  onOpenConsultantChat?: () => void;
  placementOrder?: number;
}

const OurOfferingsCardsSection = ({
  country,
  onOpenConsultantChat,
  packages,
  placementOrder
}: OurOfferingsCardsSectionProps) => {
  return (
    <DashboardStyles.Section placementOrder={placementOrder} id="offerings">
      <Container tocGutter>
        <DashboardStyles.TitleSpacing>
          <Flex vertical gap>
            <Flex justifyContent="space-between" alignItems="center">
              <TooltipHelpText variant="H4Responsive" contentTranslationKey="dashboard.ourOfferings.tooltip">
                <LocalizedText id="dashboard.ourOfferings.title" />
              </TooltipHelpText>
            </Flex>
          </Flex>
        </DashboardStyles.TitleSpacing>
      </Container>
      <OurOfferingsCards country={country} data={packages} onOpenConsultantChat={onOpenConsultantChat} />
    </DashboardStyles.Section>
  );
};

export default OurOfferingsCardsSection;
