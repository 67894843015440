import React from 'react';
import moment from 'moment-timezone';
import LocalizedText from 'src/components/LocalizedText';
import { OECOptionsProps } from '.';
import { Flex, Text, TextIcon } from '@components';
import { useVisualizer } from './context';
import ProductSelect from './productSelect';
import Styles from './styles';
import { PublishOnBackendQueueAction } from 'src/redux/modules/queueing/types';
import { User } from '@redux-modules/user/interfaces';

const VisualizerOptions: React.FC<
  Partial<
    Pick<OECOptionsProps, 'showProductSelect' | 'products' | 'isDashboard'> & {
      user: User;
      disabled?: boolean;
      doPublishOnBackendQueue?: (
        event: PublishOnBackendQueueAction['event'],
        variables: { userId?: number; startDate?: string }
      ) => void;
    }
  >
> = props => {
  const { visualizerState, setVisualizerState, isFullscreen, setIsFullscreen, hasProvider } = useVisualizer();

  if (!hasProvider) {
    throw 'Called VisualizerOptions. But no VisualizerProvider was found. Wrap your VisualizerOptions with the same VisualizerProvider you wrapped the OECVisualizerContainer or OECVisualizer component';
  }

  const onVisualizerClick = () => {
    if (props.user && props.doPublishOnBackendQueue && props.isDashboard) {
      props.doPublishOnBackendQueue('clicked_on_visualizer', {
        userId: props.user.id,
        startDate: moment.tz('Europe/Zurich').format('MM/DD/YYYY HH:mm:ss')
      });
    }
  };

  const disabled = !!props.disabled;

  return (
    <Flex alignItems="center" justifyContent="space-between" fullWidth>
      {props.showProductSelect && props.products ? <ProductSelect products={props.products} /> : <div />}
      <Flex alignItems="center" gap="default" onClick={onVisualizerClick}>
        <Styles.Filter
          disabled={disabled}
          onClick={() => setVisualizerState('tree_map')}
          data-analytics-id="31-click-oec-iframe-views"
        >
          <TextIcon
            weight="bold"
            leftIconComponent={() =>
              visualizerState === 'tree_map' ? (
                <img src="/static/icons/dashboard.svg" />
              ) : (
                <img src="/static/icons/dashboard-black.svg" />
              )
            }
            color={visualizerState === 'tree_map' ? 'onWhitePrimary' : 'onWhite'}
          >
            <LocalizedText id="dashboard.tileView" description="Tile view button" />
          </TextIcon>
        </Styles.Filter>
        <Styles.Filter
          disabled={disabled}
          onClick={() => setVisualizerState('geomap')}
          data-analytics-id="31-click-oec-iframe-views"
        >
          <TextIcon
            weight="bold"
            leftIconComponent={() =>
              visualizerState === 'geomap' ? (
                <img src="/static/icons/pin-red.svg" />
              ) : (
                <img src="/static/icons/pin.svg" />
              )
            }
            color={visualizerState === 'geomap' ? 'onWhitePrimary' : 'onWhite'}
          >
            <LocalizedText id="map" description="Map button" />
          </TextIcon>
        </Styles.Filter>
        <Styles.Filter disabled={disabled} onClick={() => setIsFullscreen(!isFullscreen)}>
          <TextIcon
            weight="bold"
            leftIconComponent={() =>
              isFullscreen ? (
                <Text.SmallParagraph weight="bold">&times;</Text.SmallParagraph>
              ) : (
                <img src="/static/icons/fullScreen.svg" />
              )
            }
          >
            {isFullscreen ? (
              <LocalizedText id="exitFullscreen" description="Fullscreen button" />
            ) : (
              <LocalizedText id="fullscreen" description="Fullscreen button" />
            )}
          </TextIcon>
        </Styles.Filter>
      </Flex>
    </Flex>
  );
};

export default VisualizerOptions;
