import React, { useRef } from 'react';

import ConsultantCard from './ConsultantCard';
import OfferingCard from './OfferingCard';
import HorizontalScroll from 'src/components/HorizontalScroll';
import useAlignHeadersHeight from 'src/hooks/useAlignHeadersHeight';
import Container from 'src/components/Container';
import { useBreakpoints } from 'src/utils/viewportUtils';

interface OurOfferingsCards {
  country?: string;
  data: any[];
  onOpenConsultantChat?: () => void;
}

const OurOfferingsCards = ({ country, data, onOpenConsultantChat }: OurOfferingsCards) => {
  const ref = useRef(null);
  const minHeaderHeight = useAlignHeadersHeight(ref, 'header');

  const { tabletAndUpInTheMiddle, tabletAndDown } = useBreakpoints();
  return (
    <Container fullWidth={tabletAndDown} tocGutter>
      <HorizontalScroll
        updateScrollOnContentChange={true}
        ref={ref}
        gapSize={tabletAndUpInTheMiddle ? '28px' : '5px'}
        hideArrowsMobile
      >
        {data.map(card => (
          <OfferingCard data={card} key={card.id} country={country} headerHeight={minHeaderHeight} />
        ))}
        <ConsultantCard onOpenConsultantChat={onOpenConsultantChat} small />
      </HorizontalScroll>
    </Container>
  );
};

export default OurOfferingsCards;
