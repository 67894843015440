import { LocalizedText, ControlButton, Flex } from '@components';
import { useBreakpoints } from '@utils/viewportUtils';
import { useRouter } from 'next/router';
import React from 'react';
import useOpenableState from '@hooks/useOpenableState';
import { additionalMobileOptions, getMobileOptions } from '../../../../options';
import Styles from './styles';
import SharedStyles from '../../styles';
import MobileMenuItem from './shared/MobileMenuItem';
import { availableLocales, Locale, useLocale } from 'src/locales';
import Link from 'next/link';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'src/redux/reducers';
import { doAuthUser } from '@redux-modules/auth/actions';
import CTAButton from '../../shared/CTAButton';
import { BodyOverflowHiddenStyle } from 'src/components/GlobalStyles';

const MobileMenu: React.FC<MobileMenuReduxProps> = ({ authProfile, user }) => {
  const router = useRouter();
  const locale = useLocale();
  const { mobile, tabletAndDown } = useBreakpoints();

  const [isOpen, { toggle, close }] = useOpenableState(false);
  const [NestedComponent, setNestedComponent] = React.useState<React.FC>();

  React.useEffect(() => {
    close();
    setNestedComponent(undefined);
  }, [router.asPath]);

  const logoutLink = `/${locale}/logout`;
  const changeLanguage = (key: Locale) => {
    window.location.assign(`/${key}${router.asPath}`);
  };

  const filteredOptions = React.useMemo(() => {
    if (!user) {
      return [];
    }
    return getMobileOptions(user).filter(option => option.enabled);
  }, [user]);

  const isMenuOpen = isOpen && tabletAndDown;
  return (
    <Flex alignItems="center">
      <Styles.Container>
        {tabletAndDown && (
          <Flex alignItems="center" justifyContent="center" onClick={toggle}>
            <img src={`/static/icons/${isOpen ? 'cross' : 'menu'}.svg`} />
          </Flex>
        )}
      </Styles.Container>
      <SharedStyles.DropdownContent variant="raised" fullWidth isOpen={isOpen}>
        {NestedComponent ? (
          <>
            <ControlButton
              leftIcon="back"
              variant="Span16_24_bold"
              iconSize="24px"
              onClick={() => setNestedComponent(undefined)}
              paddingVertical="12px"
              fullHeight={false}
            >
              <LocalizedText id="previous" />
            </ControlButton>
            <NestedComponent />
          </>
        ) : (
          <Flex vertical>
            {filteredOptions.map(option => {
              return (
                <MobileMenuItem
                  key={option.translationKey}
                  option={option}
                  variant="Span16_26_bold"
                  leftSpacing="0px"
                  onClick={() => (option.link ? undefined : setNestedComponent(option.NestedComponent))}
                />
              );
            })}
            {mobile && (
              <>
                <Styles.Delimiter />
                {additionalMobileOptions(locale).map(option => {
                  return (
                    <MobileMenuItem
                      key={option.translationKey}
                      option={option}
                      onClick={() => setNestedComponent(option.NestedComponent)}
                    />
                  );
                })}
              </>
            )}
            {!user?.onboarded ? (
              <CTAButton />
            ) : (
              <>
                <Styles.Delimiter />
                <Flex vertical gap="sm">
                  <Flex>
                    {availableLocales.map(key => (
                      <Styles.LanguageText
                        key={key}
                        color={locale === key ? 'onWhitePrimary' : 'onWhiteSecondary'}
                        onClick={locale !== key ? () => changeLanguage(key) : undefined}
                      >
                        {key.toUpperCase()}
                      </Styles.LanguageText>
                    ))}
                  </Flex>
                  {authProfile ? (
                    <Link href={logoutLink}>
                      <Styles.AuthButton data-analytics-id="7-click-on-log-out-button" variant="grey">
                        <LocalizedText id="logout" description="logout button" />
                      </Styles.AuthButton>
                    </Link>
                  ) : (
                    <Styles.AuthButton variant="grey" onClick={() => doAuthUser()}>
                      <LocalizedText id="login" description="login button" />
                    </Styles.AuthButton>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        )}
      </SharedStyles.DropdownContent>

      <Styles.Overlay enabled={isMenuOpen} />
      {isMenuOpen && <BodyOverflowHiddenStyle />}
    </Flex>
  );
};

const mapStateToProps = ({ authReducer, userReducer }: AppState) => ({
  authProfile: authReducer.profile,
  user: userReducer.user
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doAuthUser
    },
    dispatch
  );

export type MobileMenuReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
