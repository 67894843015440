import { Industry } from '../industry/interfaces';
import { Company } from '../company/interfaces';
import { BusinessType } from '../businessType/interfaces';

export interface User {
  id?: number;
  companyId?: number;
  CRMGuid?: string;
  auth0Id?: string;
  gender?: string;
  firstName?: string;
  lastName?: string;
  workEmailAddress?: string;
  phone?: string;
  receiveNewsletter?: boolean;
  isStartup?: boolean;
  businessTypeId?: number;
  businessType?: BusinessType;
  onboarded?: boolean;
  lastCRMSync?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  industries?: Industry[];
  company?: Company;
  locale?: string;
  type?: 'full' | 'light';
  isEditor?: boolean;
  businessOpportunityRole?: 'approver' | 'creator' | 'publisher';
  CMSId?: string;
  supsInfrastructure?: 'yes' | 'no';
}

export const getCRMUrl = (CRMGuid: User['CRMGuid']) =>
  CRMGuid ? `https://sge01.crm4.dynamics.com/main.aspx?pagetype=entityrecord&etn=contact&id=${CRMGuid}&forceUCI=1` : '';
