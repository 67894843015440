import { Flex, Text } from '@components';
import { LaptopAndUp, TabletAndDown } from 'src/components/stylingUtils';
import EventStyles from 'src/components/Events/EventTeaser/styles';
import styled from 'styled-components';

export default {
  Container: styled(Flex)`
    @media (max-width: 1150px) {
      padding-left: 120px;
    }

    ${props => TabletAndDown(props)} {
      display: none;
    }
  `,
  CloseButton: styled.div`
    cursor: pointer;

    ${props => LaptopAndUp(props)} {
      transition: transform 100ms ease-in-out;

      &:hover {
        transform: scale(1.3);
      }
    }
  `,
  Delimiter: styled(Flex)`
    width: 2px;
    background-color: ${props => props.theme.colors.background};
    margin: 0 64px;
  `,
  MenuColumn: styled(Flex).attrs({ vertical: true })`
    width: 280px;
    min-width: 280px;
  `,
  ContentColumn: styled(Flex).attrs({ vertical: true })`
    width: 572px;
  `,
  MenuLink: styled(Text.Span18_22)`
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.text.onWhitePrimary};
    }
  `,
  CloseButtonContainer: styled.div`
    position: absolute;
    right: 0;
    top: 0;
    padding: inherit;
  `,
  Click: styled.a`
    text-decoration: none;
    ${props => (props.onClick ? 'cursor:pointer;' : '')}
  `,
  PreviewImage: styled(EventStyles.ImageContainer)`
    min-width: 130px;
    width: 130px;
    height: 100px;
    position: static;
    z-index: 0;
  `
};
