import React from 'react';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import LocalizedText from 'src/components/LocalizedText';
import parse from 'html-react-parser';

import { useLocale } from 'src/locales';
import Card from 'src/components/Card';
import { RichComponent } from 'src/components/RichComponent';
import Button from 'src/components/Button';
import Spacing from 'src/components/Spacing';
import Text from 'src/components/Text';

import OfferingCardStyles from './styles';

interface OfferingCardProps {
  country?: string;
  data: any;
  headerHeight?: number;
  onClick?: () => void;
}

const OfferingCard = ({ data, country, headerHeight, onClick }: OfferingCardProps) => {
  const intl = useIntl();
  const locale = useLocale();
  const query = country ? `?country=${country}` : '';

  return (
    <OfferingCardStyles.Container>
      <Card disableSpaceBetween>
        <OfferingCardStyles.Header style={{ minHeight: `${headerHeight}px` }}>
          <Text.H5>
            <LocalizedText id={data.titleTranslationKey} />
          </Text.H5>
          <Spacing marginVertical="sm">
            <Text.Caption size="16px">
              <RichComponent translationKey={data.subtitleTranslationKey} variant="inline" />
            </Text.Caption>
          </Spacing>
        </OfferingCardStyles.Header>
        <OfferingCardStyles.Divider noMargin />
        <OfferingCardStyles.Content>
          <OfferingCardStyles.List>
            {parse(intl.formatMessage({ id: data.servicesTranslationKey }))}
          </OfferingCardStyles.List>
          <div>
            <Text.H6>
              {data.price ? (
                <LocalizedText id="consultingPackages.detailsPage.requestPrice" />
              ) : (
                <LocalizedText id={'consultingPackages.detailsPage.free'} />
              )}
            </Text.H6>
            <Spacing marginTop="md">
              <Link href={`/${locale}/consulting-details/${data.id}${query}#top`}>
                <Button.Large onClick={onClick} fullWidth data-analytics-id="103-consulting-package">
                  <Text.SmallerParagraph color="onPrimary">
                    <LocalizedText id="consultingPackages.button" />
                  </Text.SmallerParagraph>
                </Button.Large>
              </Link>
            </Spacing>
          </div>
        </OfferingCardStyles.Content>
      </Card>
    </OfferingCardStyles.Container>
  );
};

export default OfferingCard;
