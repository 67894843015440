import styled from 'styled-components';
import { TabletAndUp } from 'src/components/stylingUtils';

export default {
  Container: styled.div``,
  ProductWidth: styled.div`
    display: inline-block;
    width: 322px;
    height: 100%;

    > * {
      height: 100%;
    }

    :not(:last-child) {
      margin-right: ${props => props.theme.spacing.xsm};
    }

    ${props => TabletAndUp(props)} {
      width: 583px;
      :not(:last-child) {
        margin-right: ${props => props.theme.spacing.md};
      }
    }
  `,
  TitleSpacing: styled.div`
    margin-bottom: ${props => props.theme.spacing.sm};

    ${props => TabletAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.default};
    }
  `
};
