import React from 'react';
import Link from 'next/link';
import LocalizedText from 'src/components/LocalizedText';

import Card from 'src/components/Card';
import { Videos } from 'src/components/Videos';

import Breakpoints from 'src/components/Breakpoints';
import Button from 'src/components/Button';
import SVGIcon from 'src/components/SVGIcon';
import Spacing from 'src/components/Spacing';
import Text from 'src/components/Text';

import ConsultantCardStyles from './styles';

interface ConsultantCardProps {
  hasVideo?: boolean;
  onOpenConsultantChat?: () => void;
  /** If true, it has a small version regardless of the resolution */
  small?: boolean;
}

const ConsultantCard = ({ hasVideo, onOpenConsultantChat, small }: ConsultantCardProps) => {
  return (
    <ConsultantCardStyles.Container small={small}>
      <Card reversedColoristic hidePadding noHoverEffect noContentColumnDirection>
        <ConsultantCardStyles.Content small={small}>
          <ConsultantCardStyles.InfoWrapper small={small}>
            <ConsultantCardStyles.IconWrapper>
              <SVGIcon src="/static/icons/infoLine-white.svg" size="40px" />
            </ConsultantCardStyles.IconWrapper>
            <div>
              <Spacing marginVertical="lg">
                <Text.H4 color="onPrimary" lineHeight="28px">
                  <LocalizedText id="consultingPackages.consultantCard.find" />
                </Text.H4>
              </Spacing>
              <Button.Large
                variant="whiteOnPrimary"
                onClick={onOpenConsultantChat}
                fullWidth
                data-analytics-id="104-more-about-me-general"
              >
                <LocalizedText id="consultingPackages.consultantCard.button" />
              </Button.Large>
            </div>
          </ConsultantCardStyles.InfoWrapper>
          <ConsultantCardStyles.VideoWrapper small={small}>
            {!small ? (
              <>
                <Breakpoints.Mobile>
                  <Link href="https://youtu.be/_oj-xN3dQ18">
                    <a target="_blank">
                      <ConsultantCardStyles.ImageContainer />
                    </a>
                  </Link>
                </Breakpoints.Mobile>
                <Breakpoints.TabletAndUp>
                  <Videos
                    oEmbeds={[
                      {
                        html:
                          '<iframe width="200" height="113" src="https://www.youtube.com/embed/_oj-xN3dQ18?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                        thumbnail_url: 'https://i.ytimg.com/vi/_oj-xN3dQ18/maxresdefault.jpg',
                        title: '',
                        url: 'https://youtu.be/_oj-xN3dQ18'
                      }
                    ]}
                  />
                </Breakpoints.TabletAndUp>
              </>
            ) : hasVideo ? (
              <Link href="https://youtu.be/_oj-xN3dQ18">
                <a target="_blank">
                  <ConsultantCardStyles.ImageContainer />
                </a>
              </Link>
            ) : (
              <Videos
                oEmbeds={[
                  {
                    html:
                      '<iframe width="200" height="113" src="https://www.youtube.com/embed/_oj-xN3dQ18?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                    thumbnail_url: 'https://i.ytimg.com/vi/_oj-xN3dQ18/maxresdefault.jpg',
                    title: '',
                    url: 'https://youtu.be/_oj-xN3dQ18'
                  }
                ]}
              />
            )}
          </ConsultantCardStyles.VideoWrapper>
        </ConsultantCardStyles.Content>
      </Card>
    </ConsultantCardStyles.Container>
  );
};

export default ConsultantCard;
