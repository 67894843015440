import styled from 'styled-components';
import { TabletAndUp, LaptopAndUp, DesktopAndUp } from 'src/components/stylingUtils';
import SelectInputStyles from 'src/components/SelectInput/styles';
import SelectSearchStyles from 'src/components/SelectSearch_v2/styles';
import { Button, Flex, Grid, Spacing, Text } from '@components';

export default {
  Container: styled.div`
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        > * {
          text-decoration: underline;
        }
      }
    }
  `,
  TopGrid: styled.div`
    ${Grid} {
      grid-row-gap: 0px;

      > * {
        &:first-child {
          border-bottom: 1px solid ${props => props.theme.colors.disabled};
        }
      }

      ${props => TabletAndUp(props)} {
        grid-row-gap: 24px;

        > * {
          &:first-child {
            border-bottom: transparent;
          }
        }
      }
    }
  `,
  TitleSpacing: styled.div`
    margin-bottom: ${props => props.theme.spacing.sm};

    ${props => TabletAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.default};
    }

    ${props => LaptopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.md};
    }
  `,
  LeftCard: styled.div`
    background: ${props => props.theme.colors.text.onWhiteError};
    padding: ${props => props.theme.spacing.xmd};

    /* Mobile */
    /* Mobile has 4 columns, so 4 = fullwidth */
    grid-column: span 4;
    min-height: 48px;

    ${props => TabletAndUp(props)} {
      /* Tablet has 8 columns, so 4 = half */
      grid-column: span 4;
      padding: 50px;

      ${Text.H3Responsive} {
        margin-left: 0;
      }

      ${Button.Large} {
        min-width: 207px;
      }
    }

    ${Text.SmallerParagraphResponsive} {
      font-size: 14px;
    }

    ${props => LaptopAndUp(props)} {
      /* Laptop has 12 columns, so 6 = half */
      grid-column: span 5;
      min-height: auto;
    }

    ${props => DesktopAndUp(props)} {
      grid-column: span 5;
    }
  `,
  RightCard: styled.div`
    background: ${props => props.theme.colors.white};

    ${Spacing} {
      padding: ${props => props.theme.spacing.md};
    }

    ${Text.H4Responsive} {
      margin-top: 0;
    }

    ${Flex} {
      ${SelectInputStyles.Container}, ${SelectSearchStyles.Container} {
        width: 408px;
      }
    }

    label {
      align-items: start;
    }

    /* Mobile */
    /* Mobile has 4 columns, so 4 = fullwidth */
    grid-column: span 4;

    /* Tablet */
    @media (min-width: ${props => props.theme.breakpoints.small}) {
      /* Tablet has 8 columns, so 4 = half */
      grid-column: span 4;

      ${Text.H4Responsive} {
        margin-top: ${props => props.theme.spacing.md};
      }

      /* form {
        > ${Flex} {
          margin-bot
        }
      } */

      ${Button.xLarge} {
        width: 252px;
      }
    }

    /* Laptop */
    @media (min-width: ${props => props.theme.breakpoints.large}) {
      /* Laptop has 12 columns, so 6 = half */
      grid-column: span 7;

      ${Spacing} {
        padding-top: ${props => props.theme.spacing.md};
        padding-bottom: ${props => props.theme.spacing.md};
        padding-left: ${props => props.theme.spacing.lg};
        padding-right: ${props => props.theme.spacing.lg};
      }
    }

    /* Laptop */
    @media (min-width: ${props => props.theme.breakpoints.xlarge}) {
      /* Laptop has 12 columns, so 6 = half */
      grid-column: span 7;
    }
  `,
  HSCode: styled.div`
    /* Tablet */
    @media (min-width: ${props => props.theme.breakpoints.small}) {
      ${Flex} {
        position: relative;
        > ${Text.H5Responsive} {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  `,
  HSCodeContainer: styled.div`
    /* Tablet */
    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding-left: 90px;
    }

    /* Desktop */
    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      padding-left: 100px;
    }
  `,
  TodoCheckboxWrapper: styled.div`
    margin: ${props => props.theme.spacing.default} 0;
    > * {
      padding: ${props => props.theme.spacing.default} 0;
      border-bottom: 1px solid ${props => props.theme.colors.disabled};
      &:first-child {
        border-top: 1px solid ${props => props.theme.colors.disabled};
      }
    }

    label {
      a {
        color: ${props => props.theme.colors.primary};
      }
    }
  `,
  InnerModal: styled.div`
    display: block;

    ${Flex} {
      ${Button.xLarge} {
        margin-bottom: 16px;
      }

      ${Button.xxLarge} {
        ${props => LaptopAndUp(props)} {
          min-width: 230px;
        }
      }

      &:nth-child(2) {
        flex-direction: column !important;
      }
    }

    /* Tablet */
    @media (min-width: ${props => props.theme.breakpoints.small}) {
      ${Flex} {
        ${Button.xLarge} {
          margin: 0 10px 0 10px;
        }

        &:nth-child(2) {
          flex-direction: row !important;
        }
      }
    }
  `,
  NavigatorWidget: styled.div`
    ${Text.SmallerParagraphResponsive} {
      font-size: 14px;
    }
    ${Text.ParagraphResponsive} {
      font-size: 15px;
    }
  `
};
