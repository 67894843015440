import { ControlButton, Button } from '@components';
import { LaptopAndUp, DesktopAndUp, CustomAndDown, TabletAndDown } from 'src/components/stylingUtils';
import styled from 'styled-components';

export default {
  UserMenuItem: styled(ControlButton).attrs({ activeOnHover: true })`
    :hover:before {
      transition: transform 100ms ease-out 0s;
      transform: scale(1.2);
    }
  `,
  Button: styled(Button.Medium)`
    height: 42px;
    padding: 0;
    margin: 0;
    color: ${props => props.theme.colors.black};

    @media (hover: hover) {
      :hover {
        background-color: ${props => props.theme.colors.background};
      }
    }
  `,
  Delimiter: styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.lightGraySecondary};
    position: relative;
    padding: 0 ${props => props.theme.spacing.md};
    left: -${props => props.theme.spacing.md};
  `,
  UserMenuName: styled.span<{ role?: string }>`
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;

    ${props => LaptopAndUp(props)} {
      max-width: 215px;
    }

    ${props => DesktopAndUp(props)} {
      max-width: 350px;
    }

    ${CustomAndDown('1150px')} {
      max-width: ${props => (props.role ? '90px' : '215px')};
    }

    ${props => TabletAndDown(props)} {
      max-width: 170px;
    }
  `
};
