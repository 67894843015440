import { Flex, Input } from '@components';
import styled from 'styled-components';

export default {
  Container: styled(Flex).attrs({ justifyContent: 'center', alignItems: 'cetner' })`
    letter-spacing: 0.4px;
  `,
  Avatar: styled.img`
    height: 96px;
    width: 96px;
    object-fit: cover;
    border-radius: 50%;
  `,
  Link: styled.a`
    text-decoration: none;
    text-decoration-color: ${props => props.theme.colors.button.onWhite};
  `,
  ContactLinksContainer: styled(Flex)`
    span {
      color: ${props => props.theme.colors.text.onWhitePrimary};
    }
    > div:hover,
    a:hover {
      span {
        text-decoration: underline;
      }
    }
  `,
  Divider: styled(Flex)`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.lightGraySecondary};
  `,
  Textarea: styled(Input.TextArea)`
    resize: none;
  `
};
