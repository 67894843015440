import { getCRMUrl, User } from '@redux-modules/user/interfaces';
import { IntlShape } from 'react-intl';

export const getGeneralEmailContent = (user: User, intl: IntlShape, message: string) => {
  return `${intl.formatMessage({ id: 'consultantRequestBy' })}
  ${user.firstName} ${user.lastName}
  ${user.workEmailAddress}
  ${user.company?.name ? user.company.name : ''}

  ${getCRMUrl(user.CRMGuid)}

  ${intl.formatMessage({ id: 'consultantContactBodyAnswers' })}
  ${message}
`;
};
