import { ControlButton, Flex } from '@components';
import styled, { css } from 'styled-components';

const arrowDownIconAfter = css`
  content: '';
  display: block;
  position: absolute;
  background: url('/static/icons/semiArrowDownTinyPrimary.svg') center/cover no-repeat;
`;

export default {
  RelativeContainer: styled(Flex)`
    position: relative;
  `,
  Container: styled(ControlButton)<{ isLightUser?: boolean; isOpen?: boolean }>`
    box-sizing: border-box;
    transition: color 100ms ease-out;
    position: relative;
    cursor: pointer;
    z-index: 14;
    user-select: none;
    ${props =>
      !props.isLightUser
        ? `border-bottom: 4px solid ${
            props.active ? props.theme.colors.button.onWhite : props.theme.colors.button.transparent
          };`
        : `
      margin-right: ${props.theme.spacing.md};
      &:after {
        ${arrowDownIconAfter}
        width: 16px;
        height: 16px;
        right: -${props.theme.spacing.default};
      }
    `}

    ${props => props.isOpen && `> span { color: ${props.theme.colors.error}; }`}
  `
};
