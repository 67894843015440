import { AppLink, Flex, Text } from '@components';
import styled, { css } from 'styled-components';

const menuItem = css<{ selected?: boolean }>`
  padding: 9px ${props => props.theme.spacing.sm};
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      background-color: ${props => props.theme.colors.lightGraySecondary};
    }
  }

  ${props => props.selected && `background-color: ${props.theme.colors.lightGraySecondary};`}

  &:nth-child(odd) {
    border-inline: 1px solid ${props => props.theme.colors.white};
  }
`;
export default {
  ExternalLinkContainer: styled(AppLink)<{ selected?: boolean }>`
    ${menuItem}
    flex: 1;
    text-align: center;
  `,
  EmptyMenuContainer: styled(Flex)<{ selected?: boolean }>`
    ${menuItem}
    flex: 2;
    justify-content: center;
  `,
  ExternalLinkText: styled(Text.Span14_22).attrs({ whiteSpace: 'nowrap' })``
};
