import React, { useEffect, useRef } from 'react';
import Fullscreen from 'react-full-screen';
import { useVisualizer } from './context';
import ProductSelect from './productSelect';
import Styles from './styles';
import VisualizerOptions from './visualizerOptions';
import { Container, Flex, LocalizedText, Text, TooltipHelpText } from '@components';
import { SelectedProduct } from '@redux-modules/product/interfaces';
import { MarketGuide } from '@redux-modules/marketGuide/interfaces';
import { debounce } from '@utils/helpers';

export type OECVisualizerProps = {
  products: SelectedProduct[];
  url?: string;
  oec: any;
  market?: MarketGuide;
  tocGutter?: boolean;
  compact?: boolean;
  title?: string;
  isDashboard?: boolean;
};

export type OECOptionsProps = OECVisualizerProps & {
  showProductSelect?: boolean;
};

type ContentProps = {
  compact?: boolean;
  withFrame?: boolean;
  renderKey: string;
  height: string;
};

const OECVisualizer: React.FC<OECVisualizerProps> = props => {
  const { oec, title, products, compact, market, url, tocGutter } = props;
  const {
    selectedProduct,
    setSelectedProduct,
    isFullscreen,
    setIsFullscreen,
    fullscreenHeight,
    setFullscreenHeight,
    renderKey,
    setRenderKey
  } = useVisualizer();

  const ref = useRef<null | HTMLDivElement>(null);

  // Listen for changes on size
  useEffect(() => {
    setRenderKey(window.innerWidth.toString());

    let ticking = false;
    const onResize = () => {
      // Throttle the scroll
      if (!ticking) {
        window.requestAnimationFrame(() => {
          debounce(() => setRenderKey(window.innerWidth.toString()), 'visualizer-resize', 100);
          ticking = false;
        });
      }
      ticking = true;
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // Get fullscreen size
  useEffect(() => {
    setTimeout(() => {
      if (ref && ref.current) {
        const clientHeight = ref.current.clientHeight;
        setFullscreenHeight(clientHeight * 0.7); // 60% height
      }
    }, 100); // Waiting for fullscreen animation
  }, [isFullscreen]);

  // Add first product as the selected
  useEffect(() => {
    if (!selectedProduct && products[0]) {
      setSelectedProduct(products[0]);
    }
  }, [products]);

  const Content: React.FC<ContentProps> = ({ compact, withFrame, renderKey, height }) =>
    oec.checked &&
    (oec.succeeded ? (
      withFrame ? (
        <Styles.ResponsiveIframe compact={compact}>
          <iframe
            key={renderKey}
            src={url}
            width="100%"
            height={height}
            scrolling="no"
            frameBorder={0}
            data-hj-allow-iframe="true"
          />
        </Styles.ResponsiveIframe>
      ) : (
        <iframe
          key={renderKey}
          src={url}
          width="100%"
          height={height}
          scrolling="no"
          frameBorder={0}
          data-hj-allow-iframe="true"
        />
      )
    ) : (
      <Styles.ErrorMessage>
        <img src="/static/icons/technicalError.svg" />
        <Styles.ErrorMessageText>
          <Text.SmallParagraph>
            <LocalizedText id="oec.error" description="OEC Error" />
          </Text.SmallParagraph>
        </Styles.ErrorMessageText>
      </Styles.ErrorMessage>
    ));

  const Title = () =>
    title ? (
      <>{title}</>
    ) : market ? (
      <LocalizedText id="markets.exportersOfYourProducts" description="Export market title" />
    ) : (
      <LocalizedText id="dashboard.importingCountries" description="Importing countries title" />
    );

  const Header = () => (
    <Flex vertical full>
      <Flex fullWidth>
        {compact ? (
          <Text.H6 marginBottom="sm">
            <Title />
          </Text.H6>
        ) : (
          <TooltipHelpText
            variant="H4Responsive"
            marginBottom="sm"
            contentTranslationKey="dashboard.importingCountriesTooltip"
          >
            <Title />
          </TooltipHelpText>
        )}
      </Flex>
      {!compact && <VisualizerOptions {...props} disabled={!oec.succeeded} />}
      <ProductSelect {...props} />
      {process.browser && (
        <Content compact={compact} withFrame={true} renderKey={'dashboard-frame-' + renderKey} height="100%" />
      )}
    </Flex>
  );

  // DO NOT add the VisualizerProvider here!
  return (
    <>
      {compact ? (
        <Header />
      ) : (
        <Container fullHeight tocGutter={tocGutter}>
          <Header />
        </Container>
      )}
      <Fullscreen enabled={isFullscreen} onChange={isFull => setIsFullscreen(isFull)}>
        <Styles.FullScreenContainer key="full-screen-container" ref={ref} isFullScreen={isFullscreen}>
          {isFullscreen && (
            <>
              <Styles.TitleSpacing>
                <Flex fullWidth>
                  <Text.MediumTitle marginBottom="default">
                    <Title />
                  </Text.MediumTitle>
                </Flex>
                <VisualizerOptions {...props} showProductSelect disabled={!oec.succeeded} />
              </Styles.TitleSpacing>
              <Content renderKey={'full-screen-frame-' + fullscreenHeight} height={String(fullscreenHeight)} />
            </>
          )}
        </Styles.FullScreenContainer>
      </Fullscreen>
    </>
  );
};

export default OECVisualizer;
