import React from 'react';
import WebMenuItem from '../../shared/WebMenuItem';
import useOpenableState from '@hooks/useOpenableState';
import { WebMenuOption } from '../../../../../../options';
import ExpertMenuContent from './components/ExpertMenuContent';

const ExpertMenuItem: React.FC<{ option: WebMenuOption }> = ({ option }) => {
  const [isOpen, { toggle, close }] = useOpenableState(false);

  return (
    <WebMenuItem
      option={option}
      handleClick={toggle}
      handleClose={close}
      isOpen={isOpen}
      paddingVertical="64px"
      paddingHorizontal="lgxx"
    >
      <ExpertMenuContent handleClose={close} />
    </WebMenuItem>
  );
};

export default ExpertMenuItem;
