import { Flex } from '@components';
import { MobileAndDown, TabletAndDown } from 'src/components/stylingUtils';
import styled from 'styled-components';

export default {
  Container: styled.div<{ extraBottomSpacing: boolean }>`
    position: fixed;
    right: 0;
    bottom: ${props => (props.extraBottomSpacing ? '74px' : 0)};
    z-index: 2147483005;
    padding: ${props => props.theme.spacing.md};

    ${props => TabletAndDown(props)} {
      transform: scale(0.75);
      right: -22px;
      bottom: -22px;
      padding: 44px;
    }

    ${props => MobileAndDown(props)} {
      transform: scale(0.5);
      right: -40px;
      bottom: -40px;
      padding: ${props => props.theme.spacing.md};
    }
  `,
  Content: styled(Flex).attrs({ justifyContent: 'flex-end', alignItems: 'flex-end' })`
    width: ${props => props.theme.spacing.xxlg};
    height: ${props => props.theme.spacing.xxlg};
    cursor: pointer;
    position: relative;

    :hover {
      animation: pulse-big 1s;
    }
    @keyframes pulse-big {
      0% {
        transform: scaleX(1);
      }

      50% {
        transform: scale3d(1.2, 1.2, 1.2);
      }

      to {
        transform: scaleX(1);
      }
    }
  `,
  PhotoContainer: styled.img<{ showPhoto: boolean }>`
    transition: all 0.45s ease-in-out;
    position: absolute;
    opacity: ${props => (props.showPhoto ? 1 : 0)};
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    z-index: -1;
    border-radius: 50%;
    object-fit: cover;
  `,
  IconContainer: styled(Flex).attrs({ justifyContent: 'center', alignItems: 'center' })<{ showPhoto: boolean }>`
    transition: all 0.45s ease-in-out;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.error};
    width: ${props => (props.showPhoto ? '50px' : '100%')};
    height: ${props => (props.showPhoto ? '50px' : '100%')};
    > img {
      transition: all 0.45s ease-in-out;
      transform: ${props => `scale(${props.showPhoto ? 0.5208 : 1})`};
    }

    animation: pulse-box-shadow 2s infinite;
    @keyframes pulse-box-shadow {
      0% {
        box-shadow: 0 0 0 0 rgba(211, 34, 42, 0.75);
      }

      70% {
        box-shadow: 0 0 0 10px rgba(211, 34, 42, 0);
      }

      to {
        box-shadow: 0 0 0 0 rgba(211, 34, 42, 0);
      }
    }
  `
};
