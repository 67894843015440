import React, { useState } from 'react';
import ExportNavigatorStyles from './styles';
import { useRouter } from 'next/router';
import { useLocale } from 'src/locales';
import { Button, Modal, Flex, Spacing, LocalizedText, Text, LoaderButton } from '@components';

type NewQueryModalProps = {
  open: boolean;
  onCloseButtonClick: () => void;
  onNoButtonClick: () => void;
  clearMarketHSCode: () => void;
};

const NewQueryModal = (props: NewQueryModalProps) => {
  const router = useRouter();
  const locale = useLocale();
  const [submitted, setSubmitted] = useState(false);

  const restartExportGuide = async () => {
    setSubmitted(true);
    localStorage.removeItem('export-guide');
    props.clearMarketHSCode();
    await router.push(`/${locale}/export-guide/new`);
    window.scrollTo(0, 0);
  };

  return (
    <Modal open={props.open} onClose={props.onCloseButtonClick}>
      <ExportNavigatorStyles.InnerModal id="test">
        <Spacing padding="md">
          <Flex vertical>
            <Text.H3Responsive marginBottom="default">
              <LocalizedText id={'dashboard.exportNavigator.existingSession.modal.title'} />
            </Text.H3Responsive>
            <Text.Paragraph marginBottom="lg">
              <LocalizedText id={'dashboard.exportNavigator.existingSession.modal.description'} />
            </Text.Paragraph>
          </Flex>
          <Flex justifyContent="center" gap="default" paddingBottom="xsm" mobile>
            <Button.xxLarge variant="grey" onClick={props.onNoButtonClick}>
              <LocalizedText id={'dashboard.exportNavigator.existingSession.modal.buttonCancel'} />
            </Button.xxLarge>
            <LoaderButton
              size="xxLarge"
              type="button"
              disabled={submitted}
              isLoading={submitted}
              onClick={restartExportGuide}
            >
              <LocalizedText id={'dashboard.exportNavigator.existingSession.modal.buttonProceed'} />
            </LoaderButton>
          </Flex>
        </Spacing>
      </ExportNavigatorStyles.InnerModal>
    </Modal>
  );
};

export default NewQueryModal;
