import styled from 'styled-components';
import helpers from 'src/components/helpers';
import { LaptopAndUp, DesktopAndUp } from 'src/components/stylingUtils';
import { TabletAndDown } from '../../../../../components/stylingUtils';

export default {
  Container: styled.div`
    display: inline-block;
    width: 322px;

    > * {
      height: 100%;
    }

    & a {
      color: ${props => props.theme.colors.primary};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    ${props => LaptopAndUp(props)} {
      width: 378px;
    }

    ${props => TabletAndDown(props)} {
      :first-child {
        margin-left: ${props => props.theme.spacing.lg};
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.small}) {
      :first-child {
        margin-left: ${props => props.theme.spacing.default};
      }
    }
  `,
  Content: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  `,
  Divider: styled.div`
    background-color: ${props => props.theme.colors.disabled};
    height: 1px;
    width: 100%;

    margin: ${props => props.theme.spacing.sm} 0;

    ${props => LaptopAndUp(props)} {
      margin: ${props => props.theme.spacing.xsm} 0;
    }

    ${props => DesktopAndUp(props)} {
      margin: ${props => props.theme.spacing.sm} 0;
    }

    ${helpers};
  `,
  Header: styled.header`
    display: flex;
    flex-direction: column;
  `,
  List: styled.ul`
    min-height: 210px;
    white-space: normal;
    padding-bottom: 30px;
    padding-left: 15px;

    > li {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;

      @media (min-width: ${props => props.theme.breakpoints.large}) {
        font-size: 16px;
      }
    }
  `,
  ListItem: styled.li`
    line-height: 30px;
  `
};
