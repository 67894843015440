import { RequestResult } from '../../../interfaces/requests';

export const PUBLISH_ON_BACKEND_QUEUE = 'queueing/PUBLISH_ON_BACKEND_QUEUE';
export const PUBLISH_ON_BACKEND_QUEUE_SUCCESS = 'queueing/PUBLISH_ON_BACKEND_QUEUE_SUCCESS';
export const PUBLISH_ON_BACKEND_QUEUE_FAILURE = 'queueing/PUBLISH_ON_BACKEND_QUEUE_FAILURE';

export interface PublishOnBackendQueueAction {
  type: typeof PUBLISH_ON_BACKEND_QUEUE;
  event:
    | 'hs_code_modified'
    | 'selected_country_added'
    | 'selected_country_removed'
    | 'industry_modified'
    | 'newsletter_subscription_added'
    | 'newsletter_subscription_removed'
    | 'startup_status_added'
    | 'startup_status_removed'
    | 'user_completed_registration' // As a Consultant I want to see if a Contact has completed registration for an account for the customer portal.
    | 'user_successful_login' // As a Consultant I want to see if and how often a Contact has accessed the customer portal.
    | 'country_guide_loaded' // As S-GE I want to understand if a Contact has started in-depth analysis of specific markets.
    | 'clicked_mendel_on_country_guide' // As a Product Manager I want to evaluate if a user accessed the MendelOnline customs database which is linked on on the customer portal
    | 'clicked_download_on_country_guide'
    | 'clicked_on_visualizer'
    | 'clicked_download_on_event_publication'
    | 'clicked_view_video_on_event_publication'
    | 'clicked_export_navigator_started'
    | 'clicked_consulting_package_send_inquiry'
    | 'clicked_create_update_posting_of_employees_report'
    | 'viewed_business_opportunity'
    | 'clicked_business_opportunity_attachement';

  variables: any;
  onSuccess?: () => void;
  onFailure?: () => void;
}
export interface PublishOnBackendQueueSuccessAction {
  type: typeof PUBLISH_ON_BACKEND_QUEUE_SUCCESS;
}
export interface PublishOnBackendQueueFailureAction {
  type: typeof PUBLISH_ON_BACKEND_QUEUE_FAILURE;
  error?: RequestResult;
}
