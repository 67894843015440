import {
  CHECK_OEC,
  CHECK_OEC_SUCCESS,
  CHECK_OEC_FAILURE,
  CheckOecAction,
  CheckOecSuccessAction,
  CheckOecFailureAction
} from './types';

export const doCheckOec = (url: CheckOecAction['url']): CheckOecAction => ({
  type: CHECK_OEC,
  url
});

export const doCheckOecSuccess = (): CheckOecSuccessAction => ({
  type: CHECK_OEC_SUCCESS
});

export const doCheckOecFailure = (): CheckOecFailureAction => ({
  type: CHECK_OEC_FAILURE
});
