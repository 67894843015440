import { ControlButton, LanguageSelector, LocalizedText, Flex } from '@components';
import { User } from '@redux-modules/user/interfaces';
import { useBreakpoints } from '@utils/viewportUtils';
import React from 'react';
import { useLocale } from 'src/locales';
import UserMenu from './components/UserMenu';
import Styles from './styles';
import getConfig from 'next/config';

const websiteUrl = getConfig().publicRuntimeConfig.SGE_WEBSITE_URL || 'https://test.s-ge.com';

export const locale2ContactUrl = {
  en: `${websiteUrl}/en/contact-s-ge`,
  de: `${websiteUrl}/de/kontakt`,
  it: `${websiteUrl}/it/contattateci`,
  fr: `${websiteUrl}/fr/contactez-nous`
};

const HeaderControls: React.FC<{ user: User }> = ({ user }) => {
  const { tabletAndDown } = useBreakpoints();
  const locale = useLocale();

  return (
    <Flex alignItems="center" gap="sm">
      <Flex alignItems="center" gap="default" fullHeight>
        {user?.businessOpportunityRole && !tabletAndDown && (
          <ControlButton
            href={`business-opportunities/dashboard`}
            leftIcon="cardOnWhiteSecondary"
            leftActiveIcon="cardPrimary"
            activeOnHover
            fullHeight
            data-test-id="nav-bo-dashboard"
          >
            <LocalizedText id="globalNav.manageBO" />
          </ControlButton>
        )}
        <ControlButton
          leftIcon="contactOnWhiteSecondary"
          leftActiveIcon="contactPrimary"
          activeOnHover
          href={locale2ContactUrl[locale]}
          target="_blank"
          external
          fullHeight
        >
          <LocalizedText id="globalNav.contact" />
        </ControlButton>
        <UserMenu user={user} />
      </Flex>
      <Styles.Delimiter />
      <LanguageSelector />
    </Flex>
  );
};

export default React.memo(HeaderControls);
