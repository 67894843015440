import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import WidgetComponent from './components/Widget';
import { SelectedProduct } from '../../redux/modules/product/interfaces';
import { doPublishOnBackendQueue } from '../../redux/modules/queueing/actions';
import { doRetrieveProductTaxes } from '../../redux/modules/tariffTaxes/actions';
import { MendelTaxesInfo } from '../../redux/modules/tariffTaxes/interfaces';
import { AppState } from '../../redux/reducers';

export type TariffTaxesWidgetProps = ReducerProps;

const TariffTaxesWidget: React.FC<TariffTaxesWidgetProps> = props => {
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct | null>(null);
  const [productInfo, setProductInfo] = useState<MendelTaxesInfo | null>(null);

  useEffect(() => {
    setSelectedProduct(props.products[0]); // set Initial selected product as the first of the list
    setProductInfo(null);
  }, [props.market, props.products]);

  useEffect(() => {
    if (selectedProduct) {
      // Finding selected product on already fetched info
      const selectedProductInfo = props.productTaxes.find(info => info.hsCode === selectedProduct.HSCode);
      if (selectedProductInfo && selectedProductInfo.destination === props.market.ISOCode2Letter) {
        // Info already fetched, just set it
        setProductInfo(selectedProductInfo);
      } else {
        // Info need to be fetched
        props.doRetrieveProductTaxes(selectedProduct, props.market);
      }
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct) {
      if (!productInfo || productInfo.hsCode !== selectedProduct.HSCode) {
        const selectedProductInfo = props.productTaxes.find(info => info.hsCode === selectedProduct.HSCode);
        if (selectedProductInfo) {
          // Info already fetched, just set it
          setProductInfo(selectedProductInfo);
        }
      }
    }
  }, [props.productTaxes]);

  const onMendelUrlClick = () => {
    if (props.user) {
      props.doPublishOnBackendQueue('clicked_mendel_on_country_guide', {
        userId: props.user.id,
        marketName: props.market.internalName,
        startDate: moment.tz('Europe/Zurich').format('MM/DD/YYYY HH:mm:ss')
      });
    }
  };

  return (
    <WidgetComponent
      {...props}
      selected={productInfo}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      onMendelUrlClick={onMendelUrlClick}
    />
  );
};

const mapStateToProps = ({ marketGuideReducer, productReducer, tariffTaxesReducer, userReducer }: AppState) => ({
  market: marketGuideReducer.marketGuide,
  products: productReducer.products,
  productTaxes: tariffTaxesReducer.productTaxes,
  loading: tariffTaxesReducer.loading,
  error: tariffTaxesReducer.error,
  user: userReducer.user
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doRetrieveProductTaxes,
      doPublishOnBackendQueue
    },
    dispatch
  );

export type ReducerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TariffTaxesWidget);
