import useScroll from '@hooks/useScroll';
import React from 'react';
import { MobileAndDown, TabletAndDown } from 'src/components/stylingUtils';
import { createGlobalStyle } from 'styled-components';
import Styles from './styles';

const HideIntercomGlobalStyle = createGlobalStyle`
  .intercom-launcher-frame, .intercom-lightweight-app, .intercom-launcher-frame ~ div {
    opacity: 0 !important;
    pointer-events: none;
  }
  .intercom-borderless-frame, [name="intercom-notifications-frame"] {
    bottom: 138px !important;

    ${props => TabletAndDown(props)} {
      bottom: 114px !important;
    }

    ${props => MobileAndDown(props)} {
      bottom: 74px !important;
    }
  }
`;

const ContactBubble: React.FC<{ onClick?: () => void; photo?: string; extraBottomSpacing?: boolean }> = ({
  onClick,
  photo = '/static/img/default-consultant.png',
  extraBottomSpacing = false
}) => {
  const { scrollPosition } = useScroll();
  const showPhoto = scrollPosition >= 400;
  return (
    <Styles.Container extraBottomSpacing={extraBottomSpacing} data-analytics-id="144-contact-widget-red-bubble">
      <Styles.Content onClick={onClick} id="contact-bubble-icon">
        <Styles.PhotoContainer src={photo} showPhoto={showPhoto} />
        <Styles.IconContainer showPhoto={showPhoto}>
          <img src="/static/icons/contact-bubbleWhite.svg" />
        </Styles.IconContainer>
      </Styles.Content>
      <HideIntercomGlobalStyle />
    </Styles.Container>
  );
};

export default React.memo(ContactBubble);
