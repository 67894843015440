import React, { useEffect, useState } from 'react';
import Modal from '../../../components/Modal';
import ProductSearch from '../../../elements/Products';
import { UserProductPotentialProps } from '../../../elements/Products/UserProducts';
import { Breakpoints, Text, Spacing, Flex, Container, ContainerPadding, TextIcon } from '@components';
import { useBreakpoints } from '../../../utils/viewportUtils';
import BlankState from '../../BlankState';
import GenericError from '../../GenericError';
import HorizontalScroll from '../../HorizontalScroll';
import ProductTeaser from '../../HsSearch/ProductTeaser';
import LoaderButton from '../../LoaderButton';
import TooltipHelpText from '../../TooltipHelpText';
import ProductPotentialStyles from './styles';
import LocalizedText from 'src/components/LocalizedText';

type ProductSelectorProps = {
  doChangeSearch: UserProductPotentialProps['doChangeSearch'];
  doSaveProducts: UserProductPotentialProps['doSaveProducts'];
  selectedProducts: UserProductPotentialProps['selectedProducts'];
  loadingProducts: UserProductPotentialProps['loadingProducts'];
  errorProducts: UserProductPotentialProps['errorProducts'];
  fullTitle?: boolean;
};

export const ProductSelector: React.FC<ProductSelectorProps> = props => {
  const { doChangeSearch, doSaveProducts, selectedProducts, loadingProducts, errorProducts, fullTitle } = props;

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    doChangeSearch('');
  }, [modalOpen]);

  const handleClick = () => {
    doChangeSearch('');
    doSaveProducts(() => setModalOpen(false));
  };

  return (
    <>
      <a onClick={() => setModalOpen(true)}>
        <TextIcon
          shouldHaveHover
          weight="bold"
          rightIconComponent={active =>
            active ? <img src="/static/icons/editPrimary.svg" /> : <img src="/static/icons/edit.svg" />
          }
          data-analytics-id="32-click-edit-my-products"
        >
          <Breakpoints.TabletAndUp>
            <LocalizedText id="editProductsFilter" description="Edit products link" />
          </Breakpoints.TabletAndUp>
          <Breakpoints.Mobile>
            <LocalizedText id={fullTitle ? 'editProductsFilter' : 'edit'} description="Edit products link" />
          </Breakpoints.Mobile>
        </TextIcon>
      </a>
      <Modal width="100%" open={modalOpen} onClose={() => setModalOpen(false)}>
        <Spacing padding="md" data-analytics-id="hs-code-search-profile">
          <ProductSearch showBigTitle />
          <Flex alignItems="center" justifyContent="center" vertical>
            <GenericError error={errorProducts} />
            {selectedProducts.length < 1 && (
              <Text.Caption marginBottom="sm" color="onWhiteError">
                <LocalizedText id="selectAtLeastOneProduct" description="No products error" />
              </Text.Caption>
            )}
            <LoaderButton disabled={selectedProducts.length < 1} isLoading={loadingProducts} onClick={handleClick}>
              <LocalizedText id="save" description="Edit products submit" />
            </LoaderButton>
          </Flex>
        </Spacing>
      </Modal>
    </>
  );
};

const ProductPotential: React.FC<UserProductPotentialProps> = props => {
  const { laptopAndDown } = useBreakpoints();

  let scrollDistance = 950;
  if (laptopAndDown) {
    scrollDistance = 600;
  }

  const products = props.products.map(product => {
    return (
      <ProductPotentialStyles.ProductWidth key={product.HSCode}>
        <ProductTeaser
          largerPadding={!props.profile}
          product={product}
          hidePotential={props.hidePotential}
          showMarketPotential={props.showMarketPotential}
          marketProductPotential={props.marketProductPotential}
          marketProductPotentialLoading={props.marketProductPotentialLoading}
        />
      </ProductPotentialStyles.ProductWidth>
    );
  });

  return (
    <>
      <Container tocGutter={props.tocGutter}>
        <ProductPotentialStyles.TitleSpacing>
          <Flex alignItems="center" justifyContent="space-between" gap>
            {props.profile ? (
              <>
                <Breakpoints.TabletAndUp>
                  <TooltipHelpText variant="H4Responsive" contentTranslationKey="dashboard.productPotentialTooltip">
                    <LocalizedText id="myProductsTitle" description="My products title" />
                  </TooltipHelpText>
                </Breakpoints.TabletAndUp>
                <Breakpoints.Mobile>
                  <TooltipHelpText variant="H6" contentTranslationKey="dashboard.productPotentialTooltip">
                    <LocalizedText id="myProductsTitle" description="My products title" />
                  </TooltipHelpText>
                </Breakpoints.Mobile>
              </>
            ) : (
              <>
                {!props.showMarketPotential ? (
                  <>
                    <Breakpoints.TabletAndUp>
                      <TooltipHelpText variant="H4Responsive" contentTranslationKey="dashboard.productPotentialTooltip">
                        <LocalizedText id="productPotentialTitle" description="My products title" />
                      </TooltipHelpText>
                    </Breakpoints.TabletAndUp>
                    <Breakpoints.Mobile>
                      <TooltipHelpText variant="H6" contentTranslationKey="dashboard.productPotentialTooltip">
                        <LocalizedText id="productPotentialTitle" description="My products title" />
                      </TooltipHelpText>
                    </Breakpoints.Mobile>
                  </>
                ) : (
                  <>
                    <Breakpoints.TabletAndUp>
                      <TooltipHelpText variant="H4Responsive" contentTranslationKey="markets.productPotentialTooltip">
                        <LocalizedText id="productPotentialTitle" description="My products title" />
                      </TooltipHelpText>
                    </Breakpoints.TabletAndUp>
                    <Breakpoints.Mobile>
                      <TooltipHelpText variant="H6" contentTranslationKey="markets.productPotentialTooltip">
                        <LocalizedText id="productPotentialTitle" description="My products title" />
                      </TooltipHelpText>
                    </Breakpoints.Mobile>
                  </>
                )}
              </>
            )}
            <ProductSelector {...props} />
          </Flex>
        </ProductPotentialStyles.TitleSpacing>
      </Container>

      {props.errorProducts ? (
        <Container tocGutter={props.tocGutter}>
          <BlankState icon={<img src="/static/icons/listLight.svg" />}>
            <LocalizedText
              id={props.hidePotential ? 'blankState.markets.products' : 'blankState.dashboard.products'}
              description="My products"
            />
          </BlankState>
        </Container>
      ) : props.loadingProducts && !props.errorProducts ? (
        <>
          <Breakpoints.LaptopAndUp>
            <Container tocGutter={props.tocGutter}>
              <HorizontalScroll hideArrowsMobile hideArrows={props.products.length <= 2}>
                {/* Create 3 rows of similar fake loaders */}
                {Array(2)
                  .fill(2)
                  .map((_, index) => (
                    <ProductPotentialStyles.ProductWidth key={index}>
                      <ProductTeaser
                        largerPadding={!props.profile}
                        hidePotential={props.hidePotential}
                        showMarketPotential={props.showMarketPotential}
                        loading
                      />
                    </ProductPotentialStyles.ProductWidth>
                  ))}
              </HorizontalScroll>
            </Container>
          </Breakpoints.LaptopAndUp>
          <Breakpoints.TabletAndDown>
            <HorizontalScroll hideArrowsMobile hideArrows={props.products.length <= 2}>
              <ContainerPadding tocGutter={props.tocGutter}>
                {/* Create 3 rows of similar fake loaders */}
                {Array(2)
                  .fill(2)
                  .map((_, index) => (
                    <ProductPotentialStyles.ProductWidth key={index}>
                      <ProductTeaser
                        largerPadding={!props.profile}
                        hidePotential={props.hidePotential}
                        showMarketPotential={props.showMarketPotential}
                        loading
                      />
                    </ProductPotentialStyles.ProductWidth>
                  ))}
              </ContainerPadding>
            </HorizontalScroll>
          </Breakpoints.TabletAndDown>
        </>
      ) : (
        props.products &&
        props.products.length > 0 && (
          <>
            {/* I'm using breakpoints here because the desktop version need to have a container */}
            <Breakpoints.LaptopAndUp>
              <Container tocGutter={props.tocGutter}>
                <HorizontalScroll
                  hideArrowsMobile
                  scrollContentTravelDistance={scrollDistance}
                  hideArrows={props.products.length <= 2}
                >
                  {products}
                </HorizontalScroll>
              </Container>
            </Breakpoints.LaptopAndUp>
            {/* Mobile */}
            <Breakpoints.TabletAndDown>
              <HorizontalScroll
                hideArrowsMobile
                scrollContentTravelDistance={scrollDistance}
                hideArrows={props.products.length <= 2}
              >
                <ContainerPadding tocGutter={props.tocGutter}>{products}</ContainerPadding>
              </HorizontalScroll>
            </Breakpoints.TabletAndDown>
          </>
        )
      )}
    </>
  );
};

export default ProductPotential;
