import { Flex } from '@components';
import styled from 'styled-components';
import { TabletAndUp } from 'src/components/stylingUtils';

export default {
  Nav: styled.nav`
    height: 40px;
    background-color: ${props => props.theme.colors.background};
    max-width: 1920px;
    margin: 0 auto;
  `,
  Container: styled(Flex)`
    padding: 0;

    ${props => TabletAndUp(props)} {
      padding: 0 ${props => props.theme.spacing.md};
    }
  `
};
