import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Flex, Text, ControlButton, ControlDropdown, LocalizedText } from '@components';
import { User } from '@redux-modules/user/interfaces';
import { useLocale } from 'src/locales';
import Styles from './styles';
import useOpenableState from '@hooks/useOpenableState';
import { userMenuOptions } from 'src/elements/Navbar/options';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'src/redux/reducers';
import { doSetAdminMode } from '@redux-modules/app/actions';
import { user2BusinessType } from '@utils/userUtils';

const UserMenu: React.FC<{ user: User } & UserMenuReduxProps> = ({ user, adminMode, doSetAdminMode }) => {
  const locale = useLocale();
  const router = useRouter();

  const [isActive, { toggle, close }] = useOpenableState(false);

  const { firstName = '', lastName = '', isEditor, onboarded } = user;
  const businessTypeEnum = user2BusinessType(user) || 'physical';
  const isSoftwareBusinessType = businessTypeEnum === 'service';
  const fullName = `${firstName} ${lastName}`;

  React.useEffect(() => {
    close();
  }, [router.asPath]);

  const logoutLink = `/${locale}/logout`;

  const toggleAdminMode = React.useCallback(() => {
    doSetAdminMode(adminMode !== 'translations-editing' ? 'translations-editing' : null);
  }, [adminMode]);

  const filteredOptions = React.useMemo(() => {
    return userMenuOptions.filter(option => {
      if (isSoftwareBusinessType && option.translationKey === 'productsUrl') {
        return false;
      }
      if (!onboarded && option.translationKey !== 'menus.myEvents') {
        return false;
      }
      return true;
    });
  }, [user]);

  return (
    <ControlDropdown
      text={<Styles.UserMenuName role={user?.businessOpportunityRole}>{fullName}</Styles.UserMenuName>}
      leftIcon="userOnWhiteSecondary"
      leftActiveIcon="userPrimary"
      activeOnHover
      handleClick={toggle}
      handleClose={close}
      selected={isActive}
    >
      <Flex vertical gap="md">
        <Flex vertical gap="default">
          {filteredOptions.map(option => (
            <ControlButton
              activeOnHover
              key={option.translationKey}
              leftIcon={option.leftIcon}
              leftActiveIcon={option.leftActiveIcon}
              href={option.link}
              data-analytics-id={option.dataAnalyticsId}
            >
              <LocalizedText id={option.translationKey} />
            </ControlButton>
          ))}
          {isEditor && (
            <ControlButton
              activeOnHover
              leftActiveIcon="edit-squarePrimary"
              leftIcon="edit-squareOnWhiteSecondary"
              onClick={toggleAdminMode}
            >
              <LocalizedText id="onPageEditing.menuText" />
            </ControlButton>
          )}
        </Flex>
        <Styles.Delimiter />
        <Link href={logoutLink}>
          <Styles.Button data-analytics-id="7-click-on-log-out-button" fullWidth variant="lightGreySecondaryOutline">
            <Text.Span14_20 weight={700}>
              <LocalizedText id="logout" description="logout button" />
            </Text.Span14_20>
          </Styles.Button>
        </Link>
      </Flex>
    </ControlDropdown>
  );
};

const mapStateToProps = ({ appReducer }: AppState) => ({
  adminMode: appReducer.adminMode
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doSetAdminMode
    },
    dispatch
  );

export type UserMenuReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserMenu));
