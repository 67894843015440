import React from 'react';
import { WebMenuOption } from '../../../../../../options';
import WebMenuItem from '../../shared/WebMenuItem';
import useOpenableState from '@hooks/useOpenableState';
import MarketMenuContent from '../../../../shared/MarketMenuContent';

const MarketMenuItem: React.FC<{ option: WebMenuOption }> = ({ option }) => {
  const [isOpen, { toggle, close }] = useOpenableState(false);

  return (
    <WebMenuItem
      option={option}
      handleClick={toggle}
      handleClose={close}
      isOpen={isOpen}
      paddingHorizontal="xxlg"
      paddingBottom="56px"
      paddingTop="default"
    >
      <MarketMenuContent handleClose={close} isOpen={isOpen} />
    </WebMenuItem>
  );
};

export default MarketMenuItem;
