import { createGlobalStyle, css } from 'styled-components';
import HexToRGB from './HexToRGB';

const NotificationsStyle = (props: any) =>
  css`
    .notifications-component {
      position: fixed;
      max-width: inherit;
      z-index: 2147483011;
    }

    .notification__item {
      border-radius: 0px;
      border: none;
      box-shadow: none;
      margin-bottom: 3px;
    }

    .notification__message {
      font-size: 18px;
      font-family: 'Akkurat S-GE';
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 27px;
      padding-left: 27px;
      line-height: initial;
    }

    .notification-container--top-center {
      left: initial;
      max-width: initial;
      width: 100%;
      top: 18px;
    }

    .notification__item--success {
      background-color: #00bf6f;
    }

    @media (max-width: ${props.theme.breakpoints.xsmall}) {
      .notification-container--top-center {
        top: 5px;
      }
    }
  `;

const GlobalStyles = createGlobalStyle`
  html, #root, #__next {
    width: 100%;
    background-color: ${props => props.theme.colors.background};
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
  }

  body {
    width: 100%;
    height: 100%;
    overflow-x: hidden !important;
  }

  .auth0-lock-input-language,
  .auth0-lock-input-signup_via_url,
  .auth0-lock-input-ggc_continuation_url {
    display: none!important;
  }

  .auth0-lock-header {
    display: none!important
  }

  .auth0-lock-submit {
    background-color: ${props => props.theme.colors.primary}!important
  }

  .auth0-lock-overlay {
    background: hsla(0,0%,100%,.9)!important;
  }

  .auth0-lock-close-button {
    margin-top: -32px !important;
    margin-right: -32px !important;
    padding: 12px !important;
    border: 1px solid ${props => `rgba(${HexToRGB(props.theme.colors.primary)}, 0.7)`} !important;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: none;
    }

    &::after {
      position: absolute;

      font: normal normal normal 17px/1 'Font Awesome 5 Free';
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      font-weight: 900;

      box-sizing: border-box;

      margin: auto;

      display: flex;
      align-items: center;
      justify-content: center;

      content: '\f00d';
      color: ${props => `rgba(${HexToRGB(props.theme.colors.primary)}, 0.7)`};
    }
  }

  .auth0-lock-widget {
    border-radius: 6px !important;
    padding: 0 !important;
    padding-top: 6px !important;
    overflow: visible !important;
    background-color: #fff!important;
    box-shadow: -2px 12px 40px rgba(0,0,0,.1)!important;

    font-family: 'Akkurat S-GE', 'Utopia S-GE', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
    text-rendering: optimizeLegibility;
  }

  .auth0-lock-badge-bottom {
    display: none;
  }

  @media screen and (max-width: 480px) {
    .auth0-lock.auth0-lock .auth0-lock-tabs-container {
        position:static!important
    }
  }

  #baguetteBox-overlay #close-button {
    background-color: initial;
    background-image: url("/static/icons/cross.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 21px;
    height: 21px
  }

  @media only screen and (min-width: 759px) {
      #baguetteBox-overlay #close-button {
          top:20px;
          right: 20px;
          width: 29px;
          height: 29px
      }
  }

  #baguetteBox-overlay #close-button * {
      display: none
  }

  #baguetteBox-overlay .baguetteBox-button {
      border-radius: 0
  }

  #baguetteBox-overlay #next-button,#baguetteBox-overlay #previous-button {
      background-color: #fff
  }

  #baguetteBox-overlay #next-button polyline,#baguetteBox-overlay #previous-button polyline {
      stroke: #727e86
  }

  #baguetteBox-overlay #next-button {
      right: 0
  }

  #baguetteBox-overlay #previous-button {
      left: 0
  }

  #baguetteBox-overlay .full-image figcaption {
      line-height: 150%;
      font-size: 14px;
      background-color: #fff;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #556671;
      font-family: akkurat,"sans-serif"
  }

  @media only screen and (min-width: 1024px) {
      #baguetteBox-overlay .full-image figcaption {
          font-size:16px
      }
  }

  ${NotificationsStyle}
`;

export const BodyOverflowHiddenStyle = createGlobalStyle`body { overflow: hidden !important; }`;

export default GlobalStyles;
