// Ref: https://reactjs.org/docs/context.html
import React, { useState, useContext } from 'react';
import { SelectedProduct } from '../../../redux/modules/product/interfaces';

type VisualizerStates = 'tree_map' | 'geomap';

type VisualizerContext = {
  /**
   * Which product is selected to show its properties on the visualizer
   */
  selectedProduct?: SelectedProduct;
  /**
   * Updates the visualizer selected product
   */
  setSelectedProduct: (product: SelectedProduct | undefined) => void;

  /**
   * Which visualizer to show(map or tree)
   */
  visualizerState: VisualizerStates;
  /**
   * Updates the visualizer state to show
   */
  setVisualizerState: (state: VisualizerStates) => void;

  /**
   * Whether or not the visualizer is on fullscreen mode
   */
  isFullscreen: boolean;
  /**
   * Sets whether or not the visualizer is on fullscreen mode
   */
  setIsFullscreen: (isFullscreen: boolean) => void;

  /**
   * The visualizer height when on fullscreen mode
   */
  fullscreenHeight: number;
  /**
   * Updates the visualizer height when on fullscreen mode
   */
  setFullscreenHeight: (fullscreenHeight: number) => void;

  /**
   * The render key used to re-render the component when changed
   */
  renderKey: string;

  /**
   * Set the render key. The visualizer will be re-rendered if this is changed.
   */
  setRenderKey: (renderKey: string) => void;
  hasProvider: boolean;
};

const initialState: VisualizerContext = {
  // The functions will be overridden in the visualizer provider down below.
  selectedProduct: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedProduct: () => {},
  visualizerState: 'tree_map',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setVisualizerState: () => {},
  isFullscreen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsFullscreen: () => {},
  fullscreenHeight: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFullscreenHeight: () => {},
  renderKey: process.browser ? window.innerWidth.toString() : '0',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRenderKey: () => {},
  hasProvider: false
};

const VisualizerContext = React.createContext<VisualizerContext>(initialState);

/**
 * The Visualizer provider for its react context
 */
export const VisualizerProvider: React.FC<{ children: React.ReactNode }> = props => {
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct | undefined>(initialState.selectedProduct);
  const [visualizerState, setVisualizerState] = useState<VisualizerStates>(initialState.visualizerState);
  const [isFullscreen, setIsFullscreen] = useState(initialState.isFullscreen);
  const [fullscreenHeight, setFullscreenHeight] = useState(initialState.fullscreenHeight);
  const [renderKey, setRenderKey] = useState(initialState.renderKey);

  return (
    <VisualizerContext.Provider
      value={{
        hasProvider: true,
        selectedProduct,
        setSelectedProduct,
        visualizerState,
        setVisualizerState,
        isFullscreen,
        setIsFullscreen,
        fullscreenHeight,
        setFullscreenHeight,
        renderKey,
        setRenderKey
      }}
    >
      {props.children}
    </VisualizerContext.Provider>
  );
};

/**
 * A hook that returns the current Visualizer context state
 */
export const useVisualizer = () => useContext(VisualizerContext);
