import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FilterTypes, getEventFilters } from 'src/components/Events/EventFilters';
import UpcomingEvents from '../../../components/Events/UpcomingEvents';
import {
  doRetrieveMarketEvents,
  doRetrieveRelevantUserMarketEvents,
  doRetrieveMyUpcomingEvents
} from '../../../redux/modules/event/actions';
import { AppState } from '../../../redux/reducers';

const MarketUpcomingEvents: NextPage<MarketUpcomingEventsProps> = ({
  slug,
  tocGutter,
  allEvents,
  allEventsError,
  allEventsLoading,
  relevantUserEvents,
  relevantUserEventsError,
  relevantUserEventsLoading,
  myUpcomingEvents,
  myUpcomingEventsTotal,
  myUpcomingEventsLoading,
  myUpcomingEventsError,
  doRetrieveRelevantUserMarketEvents,
  doRetrieveMarketEvents,
  doRetrieveMyUpcomingEvents
}) => {
  const [selectedFilter, setSelectedFilter] = useState<FilterTypes>(FilterTypes.relevant);

  const allEventsTotal = allEvents.length || 0;
  const relevantUserEventsTotal = relevantUserEvents.length || 0;

  useEffect(() => {
    if (
      selectedFilter === FilterTypes.relevant &&
      !relevantUserEventsTotal &&
      !allEventsLoading &&
      !relevantUserEventsLoading &&
      !myUpcomingEventsLoading
    ) {
      setSelectedFilter(FilterTypes.all);
    }
  }, [selectedFilter, relevantUserEventsTotal, allEventsLoading, relevantUserEventsLoading, myUpcomingEventsLoading]);

  useEffect(() => {
    doRetrieveRelevantUserMarketEvents(slug);
    doRetrieveMarketEvents(slug);
    doRetrieveMyUpcomingEvents({ slug });
  }, [slug]);

  let events = relevantUserEvents;
  let loading = relevantUserEventsLoading;
  let error = relevantUserEventsError;

  if (selectedFilter === FilterTypes.all && allEventsTotal) {
    events = allEvents;
    loading = allEventsLoading;
    error = allEventsError;
  }

  if (selectedFilter === FilterTypes.myUpcoming && myUpcomingEventsTotal) {
    events = myUpcomingEvents;
    loading = myUpcomingEventsLoading;
    error = myUpcomingEventsError;
  }

  return (
    <UpcomingEvents
      tocGutter={tocGutter}
      events={events}
      loading={loading}
      error={error}
      selectedFilter={selectedFilter}
      filters={getEventFilters(setSelectedFilter, relevantUserEventsTotal, allEventsTotal, myUpcomingEventsTotal)}
      hideDashboardUpcomingEvents
    />
  );
};

const mapStateToProps = ({ eventReducer }: AppState) => ({
  allEvents: eventReducer.marketEvents,
  allEventsLoading: eventReducer.marketEventsLoading,
  allEventsError: eventReducer.marketEventsError,
  relevantUserEvents: eventReducer.relevantUserMarketEvents,
  relevantUserEventsLoading: eventReducer.relevantUserMarketEventsLoading,
  relevantUserEventsError: eventReducer.relevantUserMarketEventsError,
  myUpcomingEvents: eventReducer.myUpcomingEvents,
  myUpcomingEventsTotal: eventReducer.myUpcomingEventsTotal,
  myUpcomingEventsLoading: eventReducer.myUpcomingEventsLoading,
  myUpcomingEventsError: eventReducer.myUpcomingEventsError
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doRetrieveMarketEvents,
      doRetrieveRelevantUserMarketEvents,
      doRetrieveMyUpcomingEvents
    },
    dispatch
  );

export type MarketUpcomingEventsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { slug: string; tocGutter?: boolean };

export default connect(mapStateToProps, mapDispatchToProps)(MarketUpcomingEvents);
