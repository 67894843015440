import { Input } from '@components';
import { MobileAndDown, TabletAndDown } from 'src/components/stylingUtils';
import styled from 'styled-components';

export default {
  MarketsGrid: styled.div`
    display: grid;
    width: 100%;

    grid-gap: ${props => props.theme.spacing.default};
    grid-auto-flow: column;

    margin-top: ${props => props.theme.spacing.md};

    ${props => TabletAndDown(props)} {
      grid-auto-flow: row;
      grid-template-columns: repeat(2, 1fr);
    }

    ${props => MobileAndDown(props)} {
      grid-auto-flow: row;
      grid-template-columns: repeat(1, 1fr);
    }

    /* Tablet */
    @media (min-width: 730px) {
      grid-template-rows: repeat(9, 1fr);
    }

    /* Laptop */
    @media (min-width: 1280px) {
      grid-template-rows: repeat(7, 1fr);
    }
  `,
  PlaceholderIconContainer: styled.div`
    opacity: 0.83;
    cursor: pointer;
    height: 0px;
    width: 0px;
    overflow: visible;
    margin-top: 14px;
  `,
  InputText: styled(Input.Text)`
    width: 232px;
    border: none;
    border-bottom: 1px solid ${props => props.theme.colors.lightGraySecondary};
    box-shadow: none;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 30px;

    ::placeholder {
      color: ${props => props.theme.colors.text.onWhiteLight}!important;
    }
  `,
  MissingCountryWrapper: styled.div`
    height: 22px;
  `
};
